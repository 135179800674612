@import '../../static/scss/colours';
@import '../../static/scss/mixin';

.terminalsAppCoinModal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 5;
  display: flex;
  .overlayClose {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.85);
  }
  .vaultSelectCard {
    z-index: 1;
    background-color: white;
    height: 75%;
    width: 60%;
    margin: auto;
    border-radius: 30px;
    border: 1px solid $border-color;
    display: flex;
    flex-direction: column;
    color: $primary-color;
    overflow: hidden;
    position: relative;
    .header {
      height: 120px;
      border-bottom: 1px solid $border-color;
      display: flex;
      justify-content: center;
      align-items: center;
      .headIcon {
        height: 50%;
        width: auto;
      }
    }
    .headBar {
      height: 60px;
      border-bottom: 1px solid $border-color;
      padding: 0 6%;
      display: flex;
      .search {
        flex: 0 0 25%;
        background: none;
        border: none;
      }
      .appList {
        flex: 0 0 50%;
        max-width: 50%;
        border-left: 1px solid $border-color;
        border-right: 1px solid $border-color;
        display: flex;
        overflow-x: scroll;
        .appCard {
          flex: 0 0 120px;
          width: 120px;
          opacity: 0.25;
          order: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          max-height: 60px;
          overflow: hidden;
          .icon {
            height: 36px;
            width: 36px;
            object-fit: contain;
          }
          &:hover {
            opacity: 1;
            .icon {
              height: 42px;
              width: 42px;
            }
          }
          &.true {
            order: 0;
            opacity: 1;
            .icon {
              height: 45px;
              width: 45px;
            }
          }
        }
      }
    }
    .tableHeader {
      height: 30px;
      padding: 0 6%;
      display: flex;
      background-color: #eeeeee;
      .asset,
      .balance,
      .value {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
      }
      .asset {
        flex: 0 0 46%;
      }
      .balance {
        flex: 0 0 30%;
      }
      .value {
        flex: 0 0 24%;
      }
    }
    .tableView {
      height: 0;
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: auto;
      .tableItem {
        padding: 0 6%;
        flex: 0 0 120px;
        display: flex;
        cursor: pointer;
        transition: all 400ms ease;
        .asset,
        .balance,
        .value {
          display: flex;
          align-items: center;
          font-weight: 600;
          span {
            font-size: 23px;
            small {
              font-size: 16px;
            }
          }
        }
        .asset {
          flex: 0 0 46%;
          .img {
            height: 24px;
            width: 24px;
            object-fit: contain;
            margin-right: 8px;
          }
        }
        .balance {
          flex: 0 0 30%;
        }
        .value {
          flex: 0 0 24%;
        }
        &:hover {
          background: whitesmoke;
        }
      }
    }
    .loadingAnim {
      position: absolute;
      background: #ffffffe0;
      display: flex;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }
  }
}
