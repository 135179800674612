.bondWrapper{
    padding: 20px;
    border-bottom: 1px solid #e7e7e7;
}
.bondRowStyleTop{
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding-bottom: 30px;
    // font-size: 14px;
    div{
        font-weight: 700;
       font-size: 20px;
    }
    .rowLeft{
        display: flex;
        align-items: center;
       
    }
}

.bondRowStyle{
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding-bottom: 30px;
    // font-size: 14px;
    div{
        font-weight: 700;
        font-size: 14px;
    }
    .rowLeft{
        display: flex;
        align-items: center;
       
    }
}
.bondRowStyle1{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 30px;
    font-size: 14px;
}
.buyButton{
    width: 40%;
    height: 50px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    color: white;
    // background-color: green;

}