.transfer-amount-wrapper {
  margin-top: 3rem;
  width: 80%;
  margin-left: 3rem;
}

.transfer-sending-amount-box {
  padding: 0px 10px 10px 10px;
  border: 0.5px solid #e7e7e7;
  width: 100%;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.transfer-amount-labels {
  h6 {
    font-weight: 700;
  }
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
}

.div-amount-input {
  display: flex;
  height: 40%;
  justify-content: space-between;
  padding-top: 8px;
  margin: 10px;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.transfer-input {
  border: none;
  width: 170px;
  height: 70px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  padding-left: 10px;
  border-radius: 15px;
  color: #343C5B;
  height: 50%;
  margin-top: 8px;
  &:disabled {
    background-color: white;
  }
}

.transfer-input::placeholder {
  opacity: 0.25;
}

.transfer-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  opacity: 0.25;
}

.transfer-input::-ms-input-placeholder {
  /* Microsoft Edge */
  opacity: 0.25;
}

.transfrom-from-coin {
  display: flex;
  justify-content: center;
  cursor: pointer;
  width: 80px;
  height: 32px;
  background: #ffffff;
  border: 0.5px solid #e5e5e5;
  border-radius: 15px;
  padding: 10px;
}

.balance-loading {
  height: 60px;
  border-radius: 15px;
}
