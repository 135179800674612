.contentCard {
  display: flex;
  flex-direction: column;
  position: relative;
  .head {
    height: 70px;
    border-bottom: 1px solid $border-color;
    display: flex;
    flex-direction: row;
    align-items: center;
    .titleText {
      padding: 0 40px;
      font-size: 22px;
      font-weight: 600;
      color: $primary-color;
    }
    .coinNameNValue {
      height: 100%;
      padding: 0 40px;
      flex: 0 0 55%;
      display: flex;
      justify-content: space-between;
      line-height: 1;
      font-size: 22px;
      font-weight: 600;
      border-right: 1px solid $border-color;
      align-items: center;
      span {
        display: flex;
        align-items: center;
      }
      img {
        height: 35px;
        margin-right: 15px;
      }
    }
    .coinValue {
      height: 100%;
      padding: 0 40px;
      flex: 0 0 20%;
      display: flex;
      justify-content: space-between;
      line-height: 1;
      font-size: 20px;
      font-weight: 600;
      border-right: 1px solid $border-color;
      align-items: center;
      justify-content: center;
      span {
        align-items: center;
        small {
          font-size: 13px;
        }
      }
      img {
        height: 20px;
        margin-right: 8px;
      }
    }
    .tabIn {
      width: 0;
      height: 100%;
      flex: 1;
      border-right: 1px solid $border-color;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      font-weight: 600;
      margin: 0;
      position: relative;
      img {
        height: 20px;
        margin-right: 10px;
      }
      input {
        padding: 0 40px;
        height: 100%;
        width: 100%;
        background: none;
        border: none;
      }
      .dropDownList {
        top: 100%;
        left: -1px;
        right: -1px;
        height: 300% !important;
        background: white;
        position: absolute !important;
        z-index: 20;
        border: 1px solid $border-color;
        .listItem {
          height: 69px;
          border-bottom: 1px solid $border-color;
          display: flex;
          padding: 0 40px;
          align-items: center;
          font-size: 20px;
          font-weight: 500;
          img {
            height: 40%;
            margin-right: 20px;
          }
          &:last-child {
            border: none;
          }
          &:hover {
            background: #f8f9fa;
          }
        }
      }
      &:last-child {
        border: none;
      }
      &.list {
        flex: 0 0 20%;
      }
    }
  }
  .content {
    flex-grow: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .footerControlls {
    .valueNSearch {
      height: 80px;
      border: 1px $border-color;
      border-style: solid none;
      display: flex;
      .coinNameNValue {
        flex: 0 0 76%;
        padding: 0 40px;
        border-right: 1px solid $border-color;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .coinTypeTabs {
        padding: 0;
        font-size: 18px;
        display: flex;
        align-items: center;
        .tabItm {
          width: 200px;
          height: 100%;
          border-left: 1px solid $border-color;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 20px;
          font-weight: 600;
          &:hover,
          &.true {
            font-weight: bold;
            background: #f2f2f2;
          }
        }
      }
      .coinSearch {
        padding: 0 40px;
        width: 0;
        flex: 1;
        border: none;
        background: none;
        font-size: 18px;
      }
    }
    .coinListWrap {
      height: 160px !important;
      .listView {
        display: flex;
        align-items: center;
        padding: 1px 70px;
        .coinWrap {
          width: 55px;
          flex: 0 0 55px;
          height: auto;
          margin-right: 80px;
          position: relative;
          img {
            width: 100%;
            height: 100%;
            opacity: 0.5;
          }
          .appTooltip {
            display: none;
            position: absolute;
            min-width: 70px;
            min-height: 26px;
            justify-content: center;
            align-items: center;
            text-align: center;
            font-size: 14px;
            font-weight: 500;
            padding: 0 8px;
            top: calc(100% + 10px);
            left: -40%;
            right: -40%;
            background: white;
            border: 1px solid $border-color;
            &::before {
              content: '';
              position: absolute;
              top: -9px;
              width: 0;
              height: 0;
              border-left: 9px solid transparent;
              border-right: 9px solid transparent;
              border-bottom: 9px solid $border-color;
            }
            &::after {
              content: '';
              position: absolute;
              top: -8px;
              width: 0;
              height: 0;
              border-left: 8px solid transparent;
              border-right: 8px solid transparent;
              border-bottom: 8px solid white;
            }
          }
          &:hover {
            .appTooltip {
              display: flex;
            }
          }
          &.true {
            height: 65px;
            width: auto;
            flex: 0 0 65px;
            img {
              opacity: 1;
            }
          }
        }
        &::after {
          content: '';
          width: 1px;
          flex: 0 0 1px;
          height: 100%;
        }
      }
    }
  }
}
