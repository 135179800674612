.homePage {
  .homeView {
    height: 0;
    flex: 1;
    display: flex;
    .content {
      flex: 1;
      padding: 0 8%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .titleOne {
        color: $primary-color;
        font-size: 2vmax;
        font-weight: 600;
      }
      .titleTwo {
        color: $app-color;
        font-size: 3.3vmax;
        font-weight: bold;
      }
      .desc {
        width: 80%;
        line-height: 2.5;
        font-size: 1.1vmax;
        color: $text-color;
      }
      .btns {
        height: 60px;
        display: flex;
        .btnIdea,
        .btnTalk {
          cursor: pointer;
          flex: 0 0 30%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $primary-color;
          font-size: 19px;
          font-weight: 600;
          position: relative;
          overflow: hidden;
          border: 1px solid $border-color;
          img {
            height: 40%;
            width: auto;
            margin-right: 6px;
          }
          &::before {
            content: '';
            background-color: $primary-color;
            width: 100%;
            height: 60px;
            position: absolute;
            left: -100%;
            transform: rotateZ(45deg);
          }
          &:hover {
            &::before {
              transition: all 0.4s ease;
              left: 100%;
            }
          }
        }
        .btnIdea {
          background: white;
          color: $text-color;
          margin-right: 2%;
        }
        .btnTalk {
          background: white;
          color: $text-color;
        }
      }
    }
    .homeCarousel {
      flex: 0 0 40%;
      display: flex;
      flex-direction: column;
      position: relative;
      overflow: hidden;
      .list {
        position: absolute;
        left: 0;
        right: 0;
        top: -30%;
        bottom: -30%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .item {
          width: 100%;
          opacity: 1;
          transition: all 0.3s ease;
          &-enter {
            opacity: 0;
            width: 0;
          }
          &-enter-active {
            opacity: 1;
            width: 100%;
          }
          &-exit {
            opacity: 1;
            width: 100%;
          }
          &-exit-active {
            opacity: 0;
            width: 0;
          }
        }
      }
    }
  }
}
.homeMobile {
  padding-top: 20%;
  .head {
    margin: 0 15%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      margin-bottom: 2vh;
    }
  }
  .btns {
    display: flex;
    flex-direction: column;
    margin: auto 0;
    padding: 0 8%;
    .btnIdea,
    .btnTalk {
      height: 60px;
      cursor: pointer;
      flex: 0 0 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $primary-color;
      font-size: 19px;
      font-weight: 600;
      position: relative;
      overflow: hidden;
      border: 1px solid $border-color;
      img {
        height: 40%;
        width: auto;
        margin-right: 6px;
      }
      &::before {
        content: '';
        background-color: $primary-color;
        width: 100%;
        height: 60px;
        position: absolute;
        left: -100%;
        transform: rotateZ(45deg);
      }
      &:hover {
        &::before {
          transition: all 0.4s ease;
          left: 100%;
        }
      }
    }
    .btnIdea {
      background: white;
      color: $primary-color;
      margin-bottom: 6%;
    }
    .btnTalk {
      background: white;
      color: $app-color;
    }
  }
  .footer {
    flex: 0 0 65px;
    height: 65px;
    border-top: 1px solid $border-color;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    background: $app-color;
    color: white;
    cursor: pointer;
    img {
      height: 40%;
      margin-right: 3%;
      filter: brightness(0) invert(1);
    }
  }
}
