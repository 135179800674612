.videoPlayerSidebar {
  border-left: 1px $vault-border-color solid;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  &.true {
    animation: sideBarAnimate 800ms;
    flex: 0 0 600px;
  }
  &.false {
    animation: sideBarAnimateR 800ms;
    flex: 0 0 0;
  }
  .head {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $primary-color;
    position: relative;
    img {
      height: 40px;
    }
    .live {
      border: 1px solid white;
      color: #fff;
      opacity: 0.5;
      display: flex;
      position: absolute;
      right: 20px;
      align-items: center;
      font-size: 13px;
      line-height: 1;
      padding: 4px 8px;
      font-weight: 600;
      .icon {
        font-size: 8px;
        margin-right: 6px;
      }
    }
  }
  .vh {
    border-bottom: 1px $vault-border-color solid;
    height: fit-content;
    position: relative;
    img {
      width: 100%;
      height: auto;
    }
    .videoBox {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
  .tabs {
    display: flex;
    height: 50px;
    .tab {
      font-size: 15px;
      height: 100%;
      flex: 0 0 25%;
      max-width: 25%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      border-bottom: 1px solid $vault-border-color;
      &.true {
        font-weight: 600;
        border-bottom: 3px solid $primary-color;
      }
    }
  }
  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 7% 40px 0 40px;
    .profile {
      display: flex;
      margin-bottom: 5%;
      .user {
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }
      .texts {
        width: 0;
        flex: 1;
        margin-left: 10px;
        .headTitle {
          width: 100%;
          font-size: 20px;
          font-weight: 600;
          margin-bottom: 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      &.true {
        .texts {
          .headTitle {
            white-space: unset;
            overflow: unset;
            text-overflow: unset;
          }
        }
      }
    }
    .playListTitle {
      width: 100%;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 6px;
      margin: 20px 0;
    }
    .desc {
      line-height: 1.6;
      font-size: 16px;
      margin: 4px;
    }
    .videoListWrap {
      height: 0 !important;
      flex: 1;
      .videoList {
        display: flex;
        flex-direction: column;
        .vidItm {
          margin-bottom: 25px;
          height: fit-content;
          flex: 0 0 100px;
          display: flex;
          margin-bottom: 20px;
          .vhSmall {
            height: 100px;
            width: fit-content;
            position: relative;
            border: 1px solid $vault-border-color;
            .vhHolder {
              height: 100%;
              width: auto;
            }
            .videoThumb {
              position: absolute;
              top: 0;
              bottom: 0;
              right: 0;
              left: 0;
            }
          }
          .textContent {
            flex: 1;
            padding: 4px 4px 4px 16px;
            .cardTitle {
              font-size: 14px;
              font-weight: 600;
            }
            .cardDesc {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  .footer {
    height: 70px;
    border-top: 1px solid $vault-border-color;
    display: flex;
    .channel {
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 40px;
      font-size: 19px;
      font-weight: 500;
      border-right: 1px solid $vault-border-color;
    }
    .channelList {
      width: 0;
      flex: 1;
      > div {
        display: flex;
        padding: 0 20px;
        align-items: center;
        &::after {
          content: '';
          height: 100%;
          flex: 0 0 1px;
        }
        .user {
          flex: 0 0 45px;
          height: 45px;
          border-radius: 50%;
          margin-right: 20px;
          &.true {
            height: 55px;
            flex: 0 0 55px;
          }
        }
      }
    }
  }
  @keyframes sideBarAnimate {
    from {
      max-width: 0%;
    }
    to {
      max-width: 600px;
    }
  }
  @keyframes sideBarAnimateR {
    from {
      max-width: 600px;
    }
    to {
      max-width: 0;
    }
  }
}
.mobile-layout {
  .videoPlayerSidebar {
    background: white;
    flex: unset;
    border: none;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    .tabs {
      height: 50px;
      background: white;
      .tab {
        font-size: 14px;
      }
    }
    .content {
      padding: 20px 30px 0 30px;
      overflow: auto;
      display: unset;
      .videoListWrap {
        height: 100% !important;
        .videoList {
          position: relative !important;
          display: unset;
          .vidItm {
            display: flex;
            flex-direction: column;
            .textContent {
              padding: 4px;
            }
          }
        }
      }
    }
    .footer {
      height: 55px;
      .btnPower {
        width: 55px;
        img {
          height: 30px;
        }
      }
      .channel {
        font-size: 14px;
      }
    }
  }
}
