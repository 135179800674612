@import '../../static/scss/colours';
@import '../../static/scss/mixin';

.vaultSelectModal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 5;
  display: flex;
  .overlayClose {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.85);
  }
  .vaultSelectCard {
    z-index: 1;
    background-color: white;
    height: 75%;
    width: 60%;
    margin: auto;
    border-radius: 30px;
    border: 1px solid $border-color;
    display: flex;
    flex-direction: column;
    color: $primary-color;
    overflow: hidden;
    position: relative;
    .header {
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      .headIcon {
        height: 50%;
        width: auto;
      }
    }
    .search {
      width: 40%;
      height: 60px;
      background: none;
      border: 1px solid $border-color;
      border-radius: 8px;
      margin: 10px auto;
    }
    .gridView {
      height: 0;
      flex: 1;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      overflow: auto;
      justify-content: space-around;
      padding: 0 90px;
      .tableItem {
        flex: 0 0 18%;
        max-width: 18%;
        display: flex;
        cursor: pointer;
        transition: all 400ms ease;
        flex-direction: column;
        height: 42%;
        align-items: center;
        justify-content: center;
        border: 1px solid $border-color;
        border-radius: 20px;
        margin: 20px 2%;
        .img {
          height: 65px;
          margin-bottom: 15px;
        }
        .name {
          font-size: 20px;
          font-weight: 600;
          line-height: 1.4;
          text-align: center;
        }
        .balance {
          font-size: 15px;
          font-weight: 400;
        }
        &.hide {
          visibility: hidden;
        }
        &:hover {
          background: whitesmoke;
        }
      }
    }
    .loadingAnim {
      position: absolute;
      background: #ffffffe0;
      display: flex;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }
  }
}
