.footer-main {
  flex: 0 0 5.5%;
  height: 50px;
  background-color: $app-color;
  display: flex;
  align-items: center;
  color: white;
  font-size: 18px;
  text-align: center;
  position: relative;
  svg {
    margin: auto;
    height: 34%;
    cursor: pointer;
  }
  .whatIs {
    display: flex;
    margin: auto;
    cursor: pointer;
    white-space: nowrap;
    font-weight: 600;
    font-size: 20px;
    img {
      margin-left: 10px;
      filter: brightness(0) invert(1);
      height: 30px;
      width: auto;
    }
  }
  .btnClose {
    position: absolute;
    right: 25px;
    top: 0;
    bottom: 0;
    height: 34%;
    width: auto;
    margin: auto;
  }
  .offerEnds {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 60px;
    font-weight: 600;
    border-left: 3px solid white;
    small {
      font-size: 12px;
    }
  }
  &:hover {
    svg {
      transform: scale(1.01);
    }
  }
}
