@import "../../static/scss/colours";
@import "../../static/scss/mixin";

.banking {
  height: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  .assetClassWrap {
    height: 100px;
    border-bottom: 1px solid $border-color;
    display: flex;
    margin-bottom: 1rem;
    .assetClass {
      width: 0;
      flex: 1;
      display: flex;
      overflow-x: scroll;
      .assetClassCard {
        display: flex;
        align-items: center;
        padding: 0 60px;
        opacity: 0.25;
        transition: all 400ms ease;
        img {
          transition: all 400ms ease;
          height: 35%;
          width: auto;
          transform-origin: center center;
        }
        &:hover {
          opacity: 0.7;
          img {
            transform: scale(1.05);
          }
        }
        &.true {
          opacity: 1;
          img {
            transform: scale(1.1);
          }
        }
      }
    }
  }
  .allBanking {
    display: flex;
    align-items: center;
    font-size: larger;
    font-weight: 400;
    cursor: pointer;
    &:hover {
      font-weight: 700;
    }
  }
}
