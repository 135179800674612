.vault-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  .vault-dashboard {
    flex: 0 0 55%;
    max-height: 55%;
    .dashboard {
      .tab {
        height: 5.7vmin;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);
        .tab-itm {
          flex: 0 0 20%;
          max-width: 20%;
          cursor: pointer;
          display: flex;
          padding: 1.6vmin;
          border-bottom: 2px solid #0000000a;
          h5 {
            color: $primary-color;
            font-size: 1.6vmin;
            margin: auto;
            font-weight: 400;
            opacity: 0.25;
          }
          &.active,
          &:hover {
            h5 {
              font-weight: 700;
              opacity: 1;
            }
            border-bottom: 2px solid $primary-color;
          }
        }
      }
      .dash-transaction {
        display: flex;
        flex-grow: 1;
        .total-portfolio {
          flex: 0 0 30%;
          flex-direction: column;
          padding: 80px 0 80px 60px;
          .total {
            cursor: pointer;
            margin: auto 0;
            h6 {
              color: $primary-color;
              font-size: 18px;
            }
            h4 {
              color: $primary-color;
              font-size: 35px;
              font-weight: bold;
            }
            opacity: 0.7;
            &.active {
              opacity: 1;
            }
            @include xl {
              padding: 8px auto;
              h6 {
                font-size: 13px;
              }
              h4 {
                font-size: 28px;
              }
            }
          }
          .chart-section {
            display: flex;
            flex-grow: 1;
            .chart {
              position: relative;
              transform-style: preserve-3d;
              margin-right: 20px;
              span {
                width: 100%;
                height: 100%;
              }
              .chart-name {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                display: flex;
                transform: translate3d(0, 0, -1px);
                h6 {
                  font-weight: bold;
                  color: $primary-color;
                  font-size: 18px;
                  line-height: 1;
                }
              }
            }
            .view {
              display: flex;
              .indicator {
                cursor: pointer;
                margin: auto;
                h6 {
                  font-size: 20px;
                  font-weight: 500;
                  small {
                    font-size: 14px;
                    font-weight: 600;
                  }
                  svg {
                    margin: 0 10px 0 0;
                  }
                  &.inactive {
                    opacity: 0.4;
                  }
                }

                @include xl {
                  margin-left: 30px;
                  h6 {
                    font-size: 14px;
                  }
                }
              }
            }
          }
        }
        .chart-area {
          flex: 0 0 70%;
          padding-top: 20px;
          .chart-curved {
            width: 100%;
            height: 100%;
          }
        }
      }
      .loading-anim {
        display: flex;
        flex-grow: 1;
        position: relative;
        > div {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          height: 100% !important;
          width: 100% !important;
        }
      }
    }
  }
}
