@import "../../static/scss/colours";
@import "../../static/scss/mixin";

.affiliatePage {
  height: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .logo {
      width: 45%;
      margin-bottom: 1rem;
    }
    .appleDownload {
      padding: 3rem 1rem 3rem 3rem;
      height: 200px;
    }
    .androidDownload {
      padding: 3rem 3rem 3rem 1rem;
      height: 200px;
    }
    .loadingLinks {
      margin-top: 2rem;
      width: 300px;
      height: 100px;
      border-radius: 35px;
    }
    .loadingLeftLink {
      margin-right: 10px;
    }
    .loadingRightLink {
      margin-left: 10px;
    }
  }
  .footer {
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #08152d;
    color: white;
    font-size: 24px;
    font-weight: 600;
    p {
      margin: 0;
      color: white;
      &:hover {
        margin-left: 8px;
        margin-right: 8px;
        cursor: pointer;
        transition: all 400ms ease-in-out;
        transform: scale(1.1);
      }
    }
  }
}
