.issueIcedStepOne {
  flex-grow: 1;
  display: flex;
  justify-content: space-around;
  position: relative;
  .issueBond,
  .bondOverview {
    flex: 0 0 40%;
  }
  .issueBond {
    display: flex;
    flex-direction: column;
    color: $primary-color;
    &.step-0 {
      justify-content: center;
      .head {
        color: $primary-color;
        font-size: 40px;
        font-weight: bold;
      }
      p.desc {
        font-size: 16px;
        line-height: 2;
      }
      .btnLetsDoIt {
        cursor: pointer;
        padding: 0 30px;
        border: 1px solid $primary-color;
        height: 40px;
        min-width: 150px;
        margin-right: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
          background: $primary-color;
          color: white;
          font-weight: 600;
        }
      }
      .exactAmt,
      .boxContrtolls,
      .headScroll,
      .coinsListScroll {
        display: none;
      }
    }
    &.step-1 {
      padding-top: 4%;
      padding-right: 4%;
      .head {
        line-height: 1;
        color: $primary-color;
        font-size: 36px;
        font-weight: bold;
      }
      p.desc,
      .btnLetsDoIt {
        display: none;
      }
      .exactAmt {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        text-decoration: underline;
        font-size: 14px;
        font-weight: 600;
      }
      .boxContrtolls {
        margin-top: 4px;
        border: 1px solid $vault-border-color;
        height: 50px;
        display: flex;
        .coin,
        input {
          flex: 0 0 33.33%;
          border: none;
          background: none;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          font-weight: 600;
        }
        .coin {
          text-transform: capitalize;
        }
        input {
          width: 0;
          margin: auto 0;
          border-left: 1px solid $vault-border-color;
          &:valid {
            &::after {
              position: absolute;
              top: 2px;
              right: 0.5em;
              transition: all 0.05s ease-in-out;
            }
          }
        }
        &.dis {
          opacity: 0.4;
        }
      }
      .headScroll {
        margin-top: 10px;
        height: 40px !important;
        .scrollList {
          display: flex;
          .assetClass {
            cursor: pointer;
            order: 1;
            white-space: nowrap;
            font-weight: 600;
            font-size: 20px;
            display: flex;
            align-items: center;
            opacity: 0.3;
            margin-right: 15px;
            &.true {
              order: 0;
              opacity: 1;
            }
          }
        }
      }
      .coinsListScroll {
        height: 0;
        flex: 1;
        .coinsList {
          display: flex;
          flex-direction: column;
          padding: 1px;
          .coin {
            opacity: 0.6;
            margin: 8px 0;
            justify-content: space-between;
            display: flex;
            padding: 32px 30px;
            border: 1px solid $vault-border-color;
            align-items: center;
            h6 {
              display: flex;
              align-items: center;
              font-weight: 600;
              margin: 0;
              font-size: 20px;
              line-height: 1;
              img {
                height: 20px;
                margin-right: 8px;
              }
            }
            &.true {
              opacity: 1;
            }
          }
        }
      }
    }
    &.opacive {
      opacity: 0.3;
    }
  }
  .bondOverview {
    margin: 4% 0;
    border: 1px solid $vault-border-color;
    display: flex;
    flex-direction: column;
    .days {
      border: 1px $vault-border-color;
      border-style: none solid solid none;
      margin-right: auto;
      font-size: 16px;
      padding: 4px 15px;
    }
    .lionBond {
      margin: 0 auto;
      transform: translateY(-10px);
    }
    .valuationHead {
      display: flex;
      justify-content: space-between;
      background: $primary-color;
      align-items: center;
      padding: 2px 6%;
      font-size: 14px;
      color: white;
      span {
        &:last-child {
          font-weight: bold;
        }
      }
      &.big {
        text-align: center;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 16px;
        padding: 8px;
      }
    }
    .valuationsScrl {
      height: 0;
      flex: 1;
      color: $primary-color;
      .valuationsView {
        padding: 3% 6%;
        display: flex;
        flex-direction: column;
        .listItem {
          flex: 0 0 25%;
          min-height: 42px;
          border: 1px solid $vault-border-color;
          display: flex;
          padding: 0 6%;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 20px;
          &.sub {
            flex: 0 0 30%;
          }
        }
        .listDetail {
          position: relative;
          flex: 1;
          display: flex;
          flex-direction: column;
          border: 1px solid $vault-border-color;
          padding: 40px 50px;
          .head {
            line-height: 1;
            font-size: 26px;
            font-weight: 600;
          }
          .date {
            font-size: 12px;
          }
          .item {
            margin-top: 10px;
            margin-bottom: 15px;
            display: flex;
            justify-content: space-between;
            .label {
              font-weight: 600;
              .secondary {
                font-weight: normal;
              }
            }
            .value {
              text-align: right;
              .primary {
                font-weight: 600;
                &.up {
                  color: $primary-green;
                }
                &.down {
                  color: $primary-red;
                }
              }
            }
            &.big {
              font-size: 20px;
            }
          }
          .close {
            cursor: pointer;
            position: absolute;
            top: 30px;
            right: 55px;
            font-size: 30px;
          }
          &.sub {
            margin-top: 15px;
            padding: 20px;
            .subHead {
              font-size: 20px;
              font-weight: 600;
              margin-bottom: 10px;
            }
            p {
              font-size: 13px;
            }
            .nb {
              font-size: 14px;
              font-weight: 600;
            }
            .close {
              top: 15px;
              right: 25px;
              font-size: 26px;
            }
          }
        }
      }
    }
    .bondPass {
      padding: 50px 40px;
      display: flex;
      flex-direction: column;
      .text-pass {
        width: 65%;
        height: 70px;
        margin: 0 auto;
        background: none;
        border: none;
        border-bottom: 1px solid $primary-color;
        text-align: center;
        font-size: 20px;
      }
      .def-trust {
        cursor: pointer;
        width: 40%;
        height: 45px;
        padding: 10px;
        border: 1px solid $primary-color;
        margin: 0 auto;
        margin-top: 50px;
      }
    }
    &.true {
      margin: auto 0;
    }
  }
  .issueIcedLoading {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    display: flex;
  }
}
