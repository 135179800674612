.shares {
  width: 100%;
  overflow-y: hidden;
  position: absolute;
  inset: 0;
  overflow-x: hidden;

  .shares-navbar {
    width: 100%;
    height: 8%;
    border-bottom: 0.5px solid #e7e7e7;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 3rem;
    // padding-right: 3rem;
    position: relative;

    .shares-icon {
      max-width: 18%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 5px;
      font-size: 1.35rem;
      font-weight: 650;
      color: #343c5b;
      margin-right: 1rem;
      img {
        width: 80%;
        height: 80%;
        min-width: 160px;
        min-height: 40px;
      }
    }

    .search-shares {
      width: 43%;
      height: calc(100% - 2rem);
      border: 0.5px solid #e7e7e7;
      background: #fff;
      border-radius: 25px;
      padding: 0.5rem 2rem;
      display: flex;
      align-items: center;
      position: absolute;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      .all {
        width: 32%;
        height: 100%;
        border-right: 3px solid #e7e7e7;
        display: flex;
        align-items: center;
        font-size: 1rem;
        color: #343c5b;
        font-weight: 700;
        opacity: 0.25;
        /* grid-gap: 1rem; */
        /* gap: 1rem; */
        justify-content: space-between;
        padding-right: 15px;
        p {
          margin-bottom: 0;
        }
        .arroww {
          display: flex;
          align-items: center;
          img {
            width: 15px;
            height: 15px;
            cursor: pointer;
          }
        }
      }
      .im {
        width: 100%;
        height: 100%;
        // padding-left: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1rem;
        color: #343c5b;
        font-weight: 700;
        // opacity: 0.25;
        gap: 1rem;
        input {
          width: -webkit-fill-available;
          border: none;
          font-size: 1rem;
          color: #343c5b;
          font-weight: 700;
          background: transparent;
        }
        img {
          width: 20px;
        }
      }
    }

    .shares-listBtn {
      padding: 0.5rem 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(270deg, rgb(129, 203, 215) 0%, rgb(61, 67, 148) 100%);
      border-radius: 35px;
      cursor: pointer;
      font-weight: 500;
      color: white;
      font-size: 1rem;
      white-space: nowrap;
    }
  }

  .w-space {
    width: 100%;
    height: 4%;
    padding: 0 4rem 0 3rem;
    border-bottom: 0.5px solid #e7e7e7;
    .optionss {
      width: 22%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 0;
      .each-option-selected {
        background: rgba(229, 229, 229, 0.25);
        font-weight: 600;
        padding: 10px 1rem;
        border-radius: 5px;
      }
      .each-option {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 2rem;
        border-radius: 25px;
        white-space: nowrap;

        p {
          margin-bottom: 0;
          font-size: 0.9rem;
        }
        div {
          display: flex;
          gap: 5px;
          transition: all ease 0.3s;
          cursor: pointer;
          align-items: center;
          &:hover {
            scale: 1.1;
          }
        }
      }
      .top-icons {
        display: flex;
        align-items: center;
        height: 100%;
        div {
          height: 100%;
          padding: 0 2rem;
          display: flex;
          border-right: 0.25px solid #e7e7e7;
          align-items: center;
          img {
            height: 50%;
          }
        }
      }
    }
  }

  .sharesContent {
    width: 100%;
    height: calc(100% - 12%);

    .tableArea {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    .headerDiv {
      background: rgba(229, 229, 229, 0.2);
      width: 100%;
      height: 2rem;
      display: flex;
      align-items: center;
      padding: 0 3rem;
      border-bottom: 0.5px solid #e5e5e5;

      div {
        width: 37.5%;
        font-size: 0.8rem;
        font-weight: 500;
      }
    }

    .overAllData {
      width: 100%;
      height: calc(100% - 2rem);
      display: flex;
      flex-direction: column;
      overflow-y: scroll;

      .eachdata {
        display: flex;
        width: 100%;
        padding: 2rem 3rem;
        align-items: center;
        border-bottom: 0.5px solid #e5e5e5;
        transition: all ease 0.2s;
        cursor: pointer;

        &:hover {
          padding-bottom: 2.5rem;
        }

        .DataDiv {
          width: 75%;
          display: flex;

          div {
            width: 50%;
            font-weight: 500;
          }
        }

        .DataDiv1 {
          width: 72%;
          display: flex;

          div {
            width: calc(100% / 3);
            font-weight: 500;
          }
        }

        .BtnsDiv {
          width: 25%;
          display: flex;
          justify-content: space-evenly;

          div {
            padding: 0.5rem 3.25rem;
            border: 1px solid #ededed;
            border-radius: 35px;
            cursor: pointer;
            font-size: 0.9rem;
            font-weight: 500;
            transition: all ease 0.2s;
            &:hover {
              transform: scale(1.02);
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}

.shareAction {
  width: 100%;
  height: 100%;
  // border: 1px solid red;

  .actionHeading {
    width: 100%;
    height: 4.25%;
    display: flex;
    align-items: center;
    border-bottom: 0.5px solid #e7e7e7;
    border-top: 0.5px solid #e7e7e7;
    padding: 0 1.85rem;
    font-size: 1.1rem;
    font-weight: 600;
  }

  .actionList {
    width: 100%;
    height: calc(100% - 4.25% - 5rem);
    display: flex;
    flex-direction: column;
    padding: 0 1.85rem;

    .eachAction {
      padding: 3.75rem 0;
      border-bottom: 0.5px solid #e5e5e5;
      cursor: not-allowed;
      & > div:first-child {
        font-size: 1.25rem;
        font-weight: 600;
        opacity: 0.25;
      }
      & > div:nth-child(2) {
        font-size: 1rem;
        opacity: 0.25;
      }

      &:hover {
        & > div {
          transform: translateY(-3px);
        }
      }
    }
  }

  .actionCloseBtn {
    height: 5rem;
    width: 100%;
    padding: 0 1.85rem;

    div {
      width: 100%;
      height: 3.5rem;
      color: #ffffff;
      font-size: 1.1rem;
      font-weight: 600;
      cursor: pointer;
      background: #ff5e5e;
      border-radius: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all ease 0.3s;

      &:hover {
        transform: scale(1.03);
      }
    }
  }
}

.transactionSharepage {
  padding: 2rem 3rem;
  height: calc(100% - 4rem);
}
