.marketDetail {
  flex: 1;
  display: flex;
  flex-direction: column;
  .mainContents {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 20px 60px;
    margin-top: 15px;
    .breadCrumbs {
      span {
        font-size: 14px;
        line-height: 1;
        cursor: pointer;
        &.gt {
          margin: 0 4px;
        }
        &:last-child {
          font-weight: 600;
        }
        &:hover {
          font-weight: 600;
        }
      }
    }
    .headBar {
      display: flex;
      align-items: center;
      margin: 15px 0;
      img {
        height: 40px;
        width: auto;
        min-width: 40px;
        margin-right: 12px;
        object-fit: contain;
      }
      .headText {
        font-size: 28px;
        font-weight: 600;
        margin-right: auto;
      }
      .btnWhite,
      .btnBlack {
        cursor: pointer;
        border: 1px solid $primary-color;
        color: $primary-color;
        height: 96%;
        flex: 0 0 15%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        font-weight: 600;
        text-decoration: none;
        &:hover {
          background: $primary-color;
          color: white;
        }
      }
      .btnBlack {
        background: $primary-color;
        color: white;
        &:hover {
          filter: contrast(1.6);
        }
      }
    }
    .desc {
      font-size: 18px;
    }
    .rows {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .assetRow {
        flex: 0 0 45%;
        border: 1px solid $border-color;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .usd,
        .supply,
        .capital {
          display: flex;
          flex-direction: column;
          .title {
            font-size: 24px;
            line-height: 1.1;
            font-weight: 600;
          }
          .label {
            font-size: 13px;
          }
        }
        .usd {
          flex: 0 0 25%;
        }
        .supply {
          flex: 0 0 30%;
        }
        .capital {
          flex: 0 0 22%;
        }
      }
      .d-row {
        flex: 0 0 25%;
        display: flex;
        .boxView {
          flex: 0 0 44%;
          margin-right: 6%;
          border: 1px solid $border-color;
          display: flex;
          .label {
            flex: 0 0 25%;
            border-right: 1px solid $border-color;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .cData {
            width: 0;
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 24px;
              height: 24px;
            }
            span {
              margin-left: 10px;
              font-size: 19px;
            }
          }
        }
      }
    }
  }
  .tabsInv {
    border-top: 1px solid $border-color;
    flex: 0 0 60px;
    display: flex;
    .tabItm {
      flex: 0 0 33.33%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 19px;
      opacity: 0.6;
      position: relative;
      &.true {
        opacity: 1;
        font-size: 20px;
        &::before {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          height: 3px;
          font-weight: 600;
          background: $primary-color;
        }
      }
    }
  }
}
