.getMobileApp {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
  .getAppContent {
    flex: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .mainTitle {
      position: relative;
      line-height: 1.1;
      font-size: 36px;
      font-weight: 500;
      color: $primary-color;
      margin-bottom: 20px;
      margin-right: auto;
      &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: -10px;
        height: 4px;
        background: $app-color;
      }
    }
    .btnItem {
      height: 70px;
      width: 90%;
      margin: 10px 0;
      border: 1px solid $border-color;
      display: flex;
      align-items: center;
      padding: 0 20px;
      font-weight: normal;
      img {
        height: 26px;
      }
      span {
        color: $primary-color;
        text-decoration: none !important;
        margin-left: 10px;
        line-height: 1;
        font-size: 16px;
        width: 0;
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .goHome {
    height: 50px;
    background: $app-color;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: white;
    font-weight: 600;
  }
}
