.moreActionsWrapper {
  .contentCard {
    .copyContent {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .labelEndpoint {
        font-size: 19px;
      }
      .valueCpy {
        display: flex;
        align-items: center;
        .value {
          width: 0;
          flex: 1;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          margin-right: 5%;
        }
        .value,
        .cpy {
          font-size: 25px;
        }
      }
    }
  }
}
