@import '../../static/scss/colours';
@import '../../static/scss/mixin';

.selectAppModal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 5;
  display: flex;
  .overlayClose {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.85);
  }
  .settingsCard {
    z-index: 1;
    background-color: white;
    height: 60%;
    width: 90%;
    max-width: 550px;
    margin: auto;
    border-radius: 30px;
    border: 1px solid $border-color;
    display: flex;
    flex-direction: column;
    color: $primary-color;
    overflow: hidden;
    position: relative;
    .title {
      font-size: 28px;
      font-weight: 600;
      text-align: center;
      line-height: 1;
      margin: 2rem 0;
    }
    .content {
      height: 0;
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 0 10%;
      .search {
        height: 60px;
        border: 1px solid $border-color;
        border-radius: 16px;
        padding: 0 40px;
      }
      .appList {
        margin-top: 20px;
        padding: 10px 0;
        height: 0;
        flex: 1;
        overflow: auto;
        .appCard {
          height: 100px;
          border: 1px solid $border-color;
          border-radius: 16px;
          padding: 0 40px;
          margin-bottom: 15px;
          display: flex;
          align-items: center;
          position: relative;
          @include btn-animate($primary-color);
          overflow: hidden;
          .icon {
            height: 50px;
            width: 50px;
            object-fit: contain;
            margin-right: 12px;
          }
          .appName {
            font-size: 26px;
            font-weight: 600;
          }
          &.true {
            &::after {
              content: '';
              position: absolute;
              right: 0;
              top: 0;
              bottom: 0;
              width: 20px;
              background-color: $app-color;
            }
          }
        }
      }
    }
    .loadingAnim {
      position: absolute;
      background: #ffffffe0;
      display: flex;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }
  }
}
