.menuWrapper{
    display: flex;
    height: 100vh;
    
}

.mainMenu{
    width: 25%;
   border-right: 1px solid #e7e7e7;
    
    padding-top: 25px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.topSection{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.profileImg{
    width: 60px;
    height: 60px;
    border-radius: 50%;
}
.menuItemImg{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    padding: 3px;
}
.menuItem{
    width: 40px;
    height: 40px;
    margin-top: 20px;
    border: 3px solid #e5e5e5;
    border-radius: 50%;
}
.selectedMenu{
    width: 50px;
    height: 50px;
    margin-top: 20px;
    border: 3px solid black;
    border-radius: 50%;
}
.logoutIcon{
    width: 50px;
    height: 50px;  
}

// sub menu

.subMenu{
    width: 75%;
    // padding-top: 25px;
    padding:25px 22px
}

.subMenuHeader{
    font-size: 25px;
    font-weight: 700;
}
.subMenuSubHeader{
    font-size: 11px;
    font-weight: 700;
    opacity: 0.5;
}