.bondOverviewMobile {
  display: flex;
  flex-direction: column;
  .lionBond {
    margin: 0 auto;
  }
  .bondId {
    margin-top: 10px;
    height: 30px;
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    display: flex;
    .bond,
    .defCoin,
    .idVal {
      padding: 0 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 13px;
    }
    .idVal {
      border-left: 1px solid $border-color;
      border-right: 1px solid $border-color;
      width: 0;
      flex: 1;
      span {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    &.true {
      .bond,
      .defCoin {
        opacity: 0.7;
      }
    }
  }
  .valueOfBond {
    height: 35px;
    padding: 0 20px;
    font-size: 16px;
    font-weight: 500;
    background-color: $primary-color;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .value {
      .hov {
        display: none;
      }
      &:hover {
        .hov {
          display: flex;
        }
        .def {
          display: none;
        }
      }
    }
    &.true {
      opacity: 0.7;
    }
  }
  .bondDetailScroll {
    flex: 1;
    height: unset !important;
    width: unset !important;
    .view {
      padding: 20px;
      display: flex;
      flex-direction: column;
      .listItem {
        cursor: pointer;
        flex: 0 0 70px;
        border: 1px solid $vault-border-color;
        display: flex;
        padding: 0 6%;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
        &.sub {
          flex: 0 0 30%;
        }
        &:hover {
          background: whitesmoke;
        }
      }
      .coinItem {
        cursor: pointer;
        flex: 0 0 70px;
        border: 1px solid $vault-border-color;
        display: flex;
        padding: 0 6%;
        align-items: center;
        margin-bottom: 15px;
        .coinIcn {
          height: 26px;
          width: 26px;
          margin-right: 10px;
        }
        span {
          font-size: 18px;
        }
        &:hover {
          background: whitesmoke;
        }
      }
      .listDetail {
        position: relative;
        flex: 1;
        display: flex;
        flex-direction: column;
        border: 1px solid $vault-border-color;
        padding: 20px;
        .head {
          line-height: 1;
          font-size: 22px;
          font-weight: 600;
        }
        .date {
          font-size: 12px;
        }
        .item {
          margin-top: 10px;
          margin-bottom: 15px;
          display: flex;
          justify-content: space-between;
          .label {
            font-weight: 600;
            font-size: 13px;
            .secondary {
              font-weight: normal;
            }
          }
          .value {
            text-align: right;
            font-size: 13px;
            white-space: nowrap;
            .primary {
              font-weight: 600;
              &.up {
                color: $primary-green;
              }
              &.down {
                color: $primary-red;
              }
            }
          }
          &.big {
            font-size: 20px;
          }
        }
        .close {
          cursor: pointer;
          position: absolute;
          top: 15px;
          right: 25px;
          font-size: 25px;
        }
        &.sub {
          margin-top: 15px;
          padding: 20px;
          .subHead {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 10px;
          }
          p {
            font-size: 13px;
          }
          .nb {
            font-size: 14px;
            font-weight: 600;
          }
          .close {
            top: 15px;
            right: 25px;
            font-size: 26px;
          }
        }
      }
      .txnItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 15px;
        border: 1px solid $border-color;
        margin-bottom: 15px;
        .label {
          .main {
            font-size: 18px;
            font-weight: 600;
            line-height: 1;
          }
          .date {
            font-size: 12px;
          }
        }
        .value {
          font-size: 18px;
          font-weight: 600;
          color: $primary-green;
        }
      }
      .moneyMarkets {
        flex: 1;
        .moneyMarketLogo {
          bottom: 70%;
        }
        .searchWrapper {
          width: 95%;
        }
      }
      .coinTitle {
        font-size: 19px;
        line-height: 1.2;
        font-weight: 600;
      }
      .bondCurrency {
        font-size: 14px;
      }

      .actionTitle {
        font-size: 22px;
        font-weight: 600;
        margin: 10px 0 20px 0;
      }
      .actionOption {
        margin: 20px 0 10px 0;
        border: 1px solid $vault-border-color;
        padding: 0 20px;
        position: relative;
        flex: 0 0 90px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;
        .mainTitle {
          font-size: 18px;
          font-weight: 600;
        }
        .subDesc {
          font-size: 13px;
        }
        .faCpy {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 20px;
          margin: auto;
          font-size: 22px;
        }
        .copied {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 0.5;
        }
        &:hover {
          background: whitesmoke;
        }
      }
      .loginValidateText {
        padding: 20px;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        text-align: center;
      }
    }
  }
}

.bondsQueryNavbar {
  border-bottom: 0.5px solid #e5e5e5;
  font-size: 0.9rem;
  z-index: 3;
  // padding: 5px 0;
}

.bondsQueryNavbar > ul {
  display: flex;
  list-style: none;
  gap: 35px;
  width: 100%;
  margin: auto;
  padding: 0px 3rem !important;
}

.bondsQueryNavbar > ul > li {
  cursor: pointer;
  transition: all ease 0.5s;
  padding: 7px 0;
}

.bondsTransactionNav {
  font-size: calc(20px + 0.5vh);
  font-weight: 800;
  padding-bottom: 20px;
  color: #464b4e;
}

.bondsQueryDocuments {
  margin-top: 30px;
}

.bondsQueryDocuments > :first-child {
  margin: 0 70px;
  font-size: 26px;
  font-weight: bold;
  color: #464b4e;
}

.documentsBondContent {
  width: 100%;
  padding: 20px 70px;
  // border: 1px solid red;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding-top: 30px;
}

.documentsBondContent > div {
  cursor: pointer;
  transition: all ease 0.4s;
}

.documentsBondContent > div > :first-child {
  width: 12vw;
  height: 15vw;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  position: relative;
  transition: all ease 0.4s;
}

.documentsBondContent > div > :last-child {
  font-size: calc(10px + 0.3vw);
  color: #464b4e;
  font-weight: 500;
  padding-top: 15px;
}

.documentsBondContent > div:hover {
  scale: 1.1;
}
.documentContainerImg {
  width: 100%;
  height: 100%;
}
.documentOpened {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 250%;
  height: 250%;
  z-index: 2;
}

.overlay {
  inset: 0;
  z-index: 1;
  background-color: rgba($color: #000000, $alpha: 0.5);
}

.loaderParent {
  // width: 100%;
  // height: 100%;
  // padding-left: 70px;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  position: absolute;
  inset: 0;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #d3d3d3;
  width: 80px;
  height: 80px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
