.hr {
  border-top: 1px solid $border-color;
}
.addCoinComponent {
  background: white;
  flex: 1;
  display: flex;
  flex-direction: column;
  z-index: 2;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  position: relative;
  .headTitle {
    font-size: 20px;
    font-weight: 500;
    text-decoration: none;
    margin-bottom: 10px;
    padding: 0 40px;
  }
  .actionBtns {
    margin-bottom: 30px;
    padding: 0 40px;
    .botOptionWrapper {
      .botOptionList {
        justify-content: flex-start;
      }
    }
  }
  .selectVault {
    border-top: 1px solid $border-color;
    flex: 1;
    display: flex;
    flex-direction: column;
    .headTitle {
      padding: 40px;
    }
    .vaultList {
      height: 0 !important;
      flex: 1;
      > div {
        display: flex;
        flex-direction: column;
        padding: 0 40px;
        .app {
          width: 90%;
          padding: 0 40px;
          flex: 0 0 90px;
          margin-bottom: 15px;
          border: 1px solid $border-color;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          transition: all 400ms ease;
          .label {
            font-size: 16px;
            font-weight: 500;
            .appIcn {
              height: 40px;
              width: 40px;
              object-fit: contain;
              margin-right: 10px;
            }
          }
          * {
            transition: all 400ms ease;
          }
          &:hover {
            background: whitesmoke;
            * {
              transform: scale(1.1);
            }
          }
        }
      }
    }
    &.true {
      border-top: none;
      border-bottom: 1px solid $border-color;
      flex: unset;
      .app {
        width: 88%;
        padding: 0 40px;
        flex: 0 0 90px;
        margin: 0 auto;
        margin-bottom: 15px;
        border: 1px solid $border-color;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .label {
          font-size: 16px;
          font-weight: 500;
          .appIcn {
            height: 40px;
            width: 40px;
            object-fit: contain;
            margin-right: 10px;
          }
        }
      }
    }
  }
  .genarateQuote {
    height: 0 !important;
    flex: 1;
    > div {
      display: flex;
      flex-direction: column;
      padding: 40px;
      .title {
        font-size: 22px;
        margin: 0;
      }
      .group {
        margin-top: 30px;
        margin-bottom: 10px;
        .label {
          margin-bottom: 8px;
        }
        .inpWrap {
          border: 1px solid $border-color;
          display: flex;
          height: 80px;
          input {
            width: 0;
            flex: 1;
            border: none;
            padding: 0 30px;
            -moz-appearance: textfield;
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }
          .btns {
            display: flex;
            width: fit-content;
            height: 50%;
            margin: auto 10px;
            .btnPercent {
              border-radius: 4px;
              cursor: pointer;
              padding: 4px 6px;
              border: 1px solid $border-color;
              margin-left: 10px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
          .coinBox {
            flex: 0 0 30%;
            max-width: 30%;
            display: flex;
            justify-content: center;
            align-items: center;
            border-left: 1px solid $border-color;
            img {
              height: 40%;
              margin-right: 10px;
            }
            span {
              font-size: 22px;
              font-weight: 600;
            }
          }
        }
        .btBuy {
          cursor: pointer;
          background: $primary-color;
          border: 1px solid $primary-color;
          height: 70px;
          min-width: 40%;
          width: fit-content;
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 22px;
          font-weight: 600;
          &:disabled {
            opacity: 0.4;
          }
        }
      }
    }
  }
  .loadingAnim {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    background: #ffffffa0;
  }
  &.final {
    position: relative;
    margin: 0 40px;
    &.abs {
      position: absolute;
    }
    .headCoin {
      display: flex;
      height: 85px;
      border: 1px solid $border-color;
      color: $primary-color;
      font-size: 36px;
      font-weight: 600;
      padding: 0 40px;
      align-items: center;
      img {
        height: 70%;
        margin-right: 15px;
      }
    }
    .steps {
      border: 1px solid $border-color;
      border-top: none;
      min-height: 300px;
      padding: 30px 20px;
      h5 {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 15px;
      }
      p {
        margin-bottom: 15px;
      }
      .boxView {
        border: 1px solid $border-color;
        height: 60px;
        display: flex;
        margin-bottom: 25px;
        > span {
          height: 100%;
          width: 0;
          flex: 1;
          padding: 0;
          display: flex;
        }
        .ipVal {
          height: 100%;
          background: none;
          border: none;
          width: 0;
          flex: 1;
          display: flex;
          align-items: center;
          padding: 0 40px;
        }
        .boxBtn {
          cursor: pointer;
          transition: 600ms ease all;
          border-left: 1px solid $border-color;
          width: 59px;
          display: flex;
          align-items: center;
          justify-content: center;
          img,
          svg {
            height: 50%;
            width: 50%;
          }
          .qrClose {
            display: none;
          }
          &.true {
            padding: 40px;
            height: 300px;
            width: 300px;
            background: whitesmoke;
            border: none;
            margin: auto;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            padding: 20px;
            svg {
              width: 100%;
              height: 100%;
            }
            .qrClose {
              position: absolute;
              top: -12px;
              right: -12px;
              display: flex;
              height: 30px;
              width: 30px;
              border-radius: 50%;
              background: white;
              border: 1px solid $border-color;
              transform: rotate(-45deg);
              &::before,
              &::after {
                content: '';
                position: absolute;
                width: 25px;
                height: 25px;
                background-color: $border-color;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
              }
              &::before {
                width: 3px;
              }
              &::after {
                height: 3px;
              }
            }
          }
        }
      }
      .btnSubmit {
        margin-top: 25px;
        cursor: pointer;
        height: 60px;
        width: 210px;
        border: 2px solid $primary-color;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: 600;
      }
      .buttonsList {
        display: flex;
        justify-content: space-between;
        .btnClose,
        .btnCopy,
        .btnUnCover {
          cursor: pointer;
          margin-top: 25px;
          cursor: pointer;
          height: 50px;
          border: 2px solid $border-color;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 18px;
          font-weight: 600;
        }
        .btnClose,
        .btnCopy {
          flex: 0 0 22%;
        }
        .btnUnCover {
          flex: 0 0 50%;
        }
      }
    }
  }
}
.appDetail {
  border-top: 1px solid $border-color;
  background: white;
  flex: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  z-index: 2;
  padding: 20px 30px 0 30px;
  display: flex;
  flex-direction: column;
  .appWrap {
    flex: 1;
    display: flex;
    flex-direction: column;
    border: 1px solid $border-color;
    border-style: solid solid none solid;
    .apphead {
      display: flex;
      align-items: center;
      padding: 20px 40px 30px 40px;
      .icn {
        width: 50px;
        height: 50px;
        margin-right: 8px;
      }
      .texts {
        .name {
          font-size: 24px;
          line-height: 1;
          font-weight: 600;
        }
        .code {
          font-size: 18px;
        }
      }
    }
    .tabs {
      height: 40px;
      border-bottom: 1px solid $border-color;
      display: flex;
      .tab {
        flex: 0 0 33.33%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        &.true {
          font-weight: 600;
          &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 2px;
            background: black;
          }
        }
      }
    }
    .classHead {
      padding: 40px;
      padding-bottom: 6px;
      font-size: 28px;
      font-weight: 600;
    }
    .classDesc {
      padding: 0 40px;
      font-size: 15px;
      margin-bottom: 10px;
    }
    .classBox {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin: 0 40px;
      border: 1px solid $border-color;
      .boxHead {
        height: 86px;
        padding: 0 40px;
        border-bottom: 1px solid $border-color;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .value {
          font-size: 26px;
          line-height: 1;
        }
        .label {
          font-size: 13px;
        }
      }
      .boxHeadRow {
        height: 86px;
        border-bottom: 1px solid $border-color;
        display: flex;
        flex-direction: row;
        .coinVal {
          flex: 0 0 50%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .value {
            font-size: 26px;
            line-height: 1;
            width: 100%;
            border: none;
            outline: none;
            background: none;
            text-align: center;
          }
          .coinName {
            font-size: 13px;
          }
          &:first-child {
            border-right: 1px solid $border-color;
          }
        }
      }
      .boxContent {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding: 12px 40px;
        .cntntItem {
          display: flex;
          justify-content: space-between;
        }
      }
    }
    .invest {
      cursor: pointer;
      width: 30%;
      margin: 20px 40px;
      height: 50px;
      border: 1px solid $border-color;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      &:hover {
        background: $primary-color;
        color: white;
      }
    }
    .cntntItem.out {
      padding: 15px 40px;
      display: flex;
      justify-content: space-between;
    }
  }
}
.succes {
  border-top: 1px solid $border-color;
  background: white;
  flex: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  z-index: 2;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .succesHead {
    font-size: 28px;
    font-weight: 600;
  }
  .succesDesc {
    margin-top: 10px;
    font-size: 22px;
  }
  .goToMyInvestments {
    cursor: pointer;
    margin-top: 20px;
    font-size: 22px;
    font-weight: 500;
    border: 1px solid $border-color;
    width: fit-content;
    padding: 8px 20px;
    &:hover {
      background: $primary-color;
      color: white;
    }
  }
}
