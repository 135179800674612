.getMobileApp {
  display: flex;
  flex-direction: column;
  width: 100%;
  .navbar {
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid $border-color;
    .logo {
      height: 80%;
      width: auto;
    }
  }
  .getAppContent {
    flex: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .mainTitle {
      position: relative;
      line-height: 1.1;
      font-size: 36px;
      font-weight: 600;
      color: $primary-color;
      margin-bottom: 20px;
      margin-right: auto;
      &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: -10px;
        height: 4px;
        background: $app-color;
      }
    }
    .btnItem {
      height: 70px;
      width: 90%;
      margin: 10px 0;
      border: 1px solid $border-color;
      display: flex;
      align-items: center;
      padding: 0 20px;
      font-weight: normal;
      img {
        height: 26px;
      }
      span {
        color: $primary-color;
        text-decoration: none !important;
        margin-left: 10px;
        line-height: 1;
        font-size: 16px;
        width: 0;
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .goHome {
    height: 50px;
    background: $app-color;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: white;
    font-weight: 600;
  }
  .home {
    flex: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .logo {
      width: 75%;
      margin: 0 auto;
    }
    .text {
      font-size: 19px;
      width: 80%;
      text-align: center;
      margin: 12% auto;
    }
    .btGetApp {
      cursor: pointer;
      width: 50%;
      text-align: center;
      margin: 0 auto;
      border: 1px solid $primary-color;
      padding: 12px 15px;
      border-radius: 6px;
    }
  }
  .sendAppLink {
    flex: 1;
    display: flex;
    flex-direction: column;
    .contentInvite {
      position: relative;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 20px;
      min-height: 400px;
      color: $primary-color;
      .title {
        font-size: 30px;
        font-weight: 600;
      }
      label {
        font-size: 18px;
        color: $primary-color;
        margin-bottom: 20px;
      }
      .links {
        height: 90px;
        display: flex;
        justify-content: space-between;
        .link {
          border: 1px solid $border-color;
          border-radius: 8px;
          flex: 0 0 29%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          img {
            width: 35px;
            height: 35px;
            object-fit: contain;
            margin-bottom: 4px;
          }
          span {
            font-size: 15px;
            font-weight: 500;
          }
          &:hover {
            background: whitesmoke;
          }
          &.true {
            border: 1px solid $primary-color;
          }
        }
      }
      .inputBox {
        height: 60px;
        width: 100%;
        border: 1px solid $border-color;
        border-radius: 5px;
        display: flex;
        position: relative;
        .dropDown {
          border-right: 1px solid $border-color;
          width: 60px;
          display: flex;
          align-items: center;
          margin: 0;
          position: relative;
          img {
            margin: auto;
            width: 50px;
            height: 50px;
            object-fit: cover;
          }
          input {
            width: 0;
            flex: 1;
            border: none;
            outline: none;
            font-size: 24px;
          }
        }
        .number {
          width: 0;
          flex: 1;
          outline: none;
          border: none;
          padding: 0 30px;
          font-size: 17px;
          -moz-appearance: textfield;
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          &[type='number'] {
            letter-spacing: 5px;
          }
          &::placeholder {
            letter-spacing: normal;
            font-size: 18px;
          }
        }
        .countryFlag {
          border-left: 1px solid $border-color;
          width: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0;
          .flag {
            object-fit: contain;
            height: 50%;
            width: 60%;
          }
        }
        .dropList {
          display: none;
        }
        &.true {
          border-radius: 15px 15px 0 0;
          .dropList {
            border: 1px none $border-color;
            border-style: none solid solid solid;
            border-radius: 0 0 15px 15px;
            background: white;
            position: absolute;
            top: calc(100% + 1px);
            left: -1px;
            right: -1px;
            height: 130px;
            display: flex;
            flex-direction: column;
            overflow: auto;
            &::-webkit-scrollbar {
              width: 0px !important;
              height: 0px !important;
            }
            &::-webkit-scrollbar-track {
              background: transparent;
            }
            .item {
              width: 100%;
              padding: 12px 30px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              border-bottom: 1px solid $border-color;
              img {
                height: 32px;
                width: auto;
              }
            }
          }
        }
        &.res {
          width: 80%;
          margin: 0 auto;
          margin-top: 25px;
          height: 55px;
          padding: 0 40px;
          display: flex;
          align-items: center;
          span {
            flex: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      .inputBoxIn {
        height: 70px;
        width: 100%;
        border-radius: 4px;
        display: flex;
        .boxApp {
          cursor: pointer;
          border: 1px solid $border-color;
          flex: 0 0 47%;
          margin-right: 3%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 21px;
          img {
            height: 22px;
            width: 22px;
          }
          &:hover {
            background: whitesmoke;
          }
        }
      }
      .hCongrats {
        font-size: 34px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 30px;
      }
      .message {
        margin: 0 auto;
        font-size: 18px;
        width: 70%;
        text-align: center;
        text-transform: capitalize;
      }
      .downloadDesc {
        margin: 0 auto;
        flex: 0 0 90%;
        max-width: 90%;
        text-align: center;
        font-size: 21px;
        line-height: 2;
      }
      .selectCountry {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background: white;
        display: flex;
        flex-direction: column;
        .title {
          padding: 20px;
        }
        .searchWrapper {
          border: 1px solid $border-color;
          margin: 0 20px;
          padding: 0 20px;
          height: 45px;
          display: flex;
          align-items: center;
          .number {
            flex: 1;
            border: none;
          }
        }
        .dropList {
          height: 0;
          flex: 1;
          overflow-y: auto;
          padding: 15px 20px;
          .item {
            height: 90px;
            padding: 0 20px;
            margin: 15px 0;
            display: flex;
            align-items: center;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12),
              0px 0px 1px rgba(0, 0, 0, 0.48);
            .img {
              height: 60%;
              margin-right: 20px;
            }
            .name {
              font-size: 18px;
              margin-right: auto;
            }
          }
        }
      }
    }
  }
}
