@import '../../static/scss/colours';
@import '../../static/scss/mixin';

.selectBondIssuerModal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 5;
  display: flex;
  .overlayClose {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.85);
  }
  .settingsCard {
    z-index: 1;
    background-color: white;
    height: 60%;
    width: 90%;
    max-width: 550px;
    margin: auto;
    border-radius: 30px;
    border: 1px solid $border-color;
    display: flex;
    flex-direction: column;
    color: $primary-color;
    overflow: hidden;
    position: relative;
    .loadingAnim {
      position: absolute;
      background: #ffffffe0;
      display: flex;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }
    .inCard {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0;
      color: #343C5B;
      .title {
        font-size: 28px;
        font-weight: bold;
        text-align: center;
        margin: 30px 0;
      }
      .search {
        width: 75%;
        padding: 0 30px;
        border: 1px solid $border-color;
        border-radius: 10px;
        height: 60px;
        display: flex;
        input {
          width: 0;
          flex: 1;
          border: none;
          background: none;
        }
      }
      .settingCards {
        width: 100%;
        height: 0;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 40px;
        padding-bottom: 4%;
        overflow: auto;
        &::after {
          content: '';
          flex: 0 0 20px;
        }
        .settingCard {
          display: flex;
          width: 75%;
          flex: 0 0 24%;
          border: 1px solid $border-color;
          border-radius: 10px;
          align-items: center;
          padding: 0 30px;
          @include btn-animate($primary-color);
          margin-bottom: 3%;
          position: relative;
          .icon {
            height: 35px;
            width: 35px;
            object-fit: contain;
            margin-right: 15px;
          }
          .texts {
            display: flex;
            flex-direction: column;
            font-size: 20px;
            line-height: 1;
            font-weight: 600;
            span {
              font-size: 14px;
              font-weight: 400;
            }
          }
          &.true {
            &::after {
              content: '';
              position: absolute;
              top: 0;
              bottom: 0;
              right: 0;
              width: 20px;
              background: $app-color;
            }
          }
        }
      }
    }
  }
}
