.mobile-layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  .mobile-navbar {
    height: 64px;
    display: flex;
    position: relative;
    background-color: white;
    .hamburger {
      z-index: 3;
    }
    .title {
      display: flex;
      flex: 1;
      font-size: 28px;
      font-weight: bold;
      margin-right: 70px;
      align-items: center;
      justify-content: center;
    }
    .menu-open {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      background-color: white;
      z-index: 2;
      .profile {
        flex: 0 0 33%;
        max-height: 33%;
        background-color: $primary-color;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        .proPic {
          border: white 2px solid;
          margin: 0 auto;
          height: 35%;
          border-radius: 50%;
        }
        .logo {
          filter: brightness(0) invert(1);
          height: 30%;
        }
        .user-info {
          .name {
            color: white;
            text-align: center;
            margin: 0;
            font-size: 14px;
          }
          .balance {
            color: white;
            text-align: center;
            font-size: 22px;
            font-weight: bold;
          }
        }
      }
      .menu {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        .menu-itm {
          font-size: 19px;
          color: $primary-color;
          text-decoration: none;
          font-weight: 400;
          &.true {
            font-weight: bold;
            font-size: 24px;
          }
        }
      }
      .logout-btn {
        border: 1px solid $primary-color;
        background: $primary-color;
        color: white;
        font-weight: bold;
        padding: 16px;
        width: 100%;
        text-align: center;
      }
    }
  }
  .mobile-content {
    height: 0;
    flex: 1;
    overflow: auto;
  }
  .mobile-footer {
    height: 65px;
    background-color: white;
    display: flex;
    box-shadow: 0px -2px 3px 0px rgba(0, 0, 0, 0.08);
    .footer-itm {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      svg {
        height: 25px;
        opacity: 0.8;
      }
      .tab-name {
        text-decoration: none !important;
        margin-top: 3px;
        line-height: 1;
        text-align: center;
        color: $primary-color;
        font-size: 12px;
        font-weight: 600;
        display: none;
      }
      &.true {
        svg {
          transform: scale(1.1);
          path {
            fill: $primary-color;
            stroke: $primary-color;
          }
        }
        .tab-name {
          display: block;
        }
      }
      &:hover {
        text-decoration: none;
      }
    }
  }
}
