.tellers {
  width: 100%;
  overflow-y: hidden;
  position: absolute;
  inset: 0;
  overflow-x: hidden;
  background: #181c1c;

  .tellers-content {
    display: flex;
    flex-direction: column;
    /* border: 1px solid white; */
    width: 50%;
    height: 50%;
    margin: auto;
    justify-content: flex-end;
    align-items: center;
    gap: 25px;

    img {
      width: 70%;
    }

    .tellers-txt {
      color: #ffffff;
      font-size: 1.65rem;
      font-weight: 600;
    }
  }

  .tellers-footer {
    color: #fff;
    font-size: 1.4rem;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 5%;
    /* right: 38%; */
    width: 100%;
  }
}
