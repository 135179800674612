.each-card {
  cursor: pointer;
  margin: 0px;
  margin-top: 0px;
  border: 0px;
  border-bottom: 1px solid #e5e5e5;
  border-radius: 0px;
  padding: 20px 0;
  transition: all ease 0.3s;
}
.each-card:hover {
  // transform: scale(1.03);
  // transition: ease 0.3s;
  background: rgba(242, 242, 242, 0.5);
}
.eachapp {
  display: flex;
  // margin: 20px 0;
  padding-left: 1.85rem;
  align-items: baseline;
  .app-icon {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    // margin-left: 25px;
  }
  .app-name {
    font-weight: 700;
    font-size: 17px;
    line-height: 21px;
    margin-left: 20px;
  }
}
