.videoPlayerSidebar {
  border-left: 1px #e7e7e7 solid;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.videoPlayerSidebar.true {
  animation: sideBarAnimate 800ms;
  flex: 0 0 600px;
}

.videoPlayerSidebar.false {
  animation: sideBarAnimateR 800ms;
  flex: 0 0 0;
}

.videoPlayerSidebar .head {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #182542;
  position: relative;
}

.videoPlayerSidebar .head img {
  height: 40px;
}

.videoPlayerSidebar .head .live {
  border: 1px solid white;
  color: #fff;
  opacity: 0.5;
  display: flex;
  position: absolute;
  right: 20px;
  align-items: center;
  font-size: 13px;
  line-height: 1;
  padding: 4px 8px;
  font-weight: 600;
}

.videoPlayerSidebar .head .live .icon {
  font-size: 8px;
  margin-right: 6px;
}

.videoPlayerSidebar .vh {
  border-bottom: 1px #e7e7e7 solid;
  height: fit-content;
  position: relative;
}

.videoPlayerSidebar .vh img {
  width: 100%;
  height: auto;
}

.videoPlayerSidebar .vh .videoBox {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.videoPlayerSidebar .tabs {
  display: flex;
  height: 50px;
}

.videoPlayerSidebar .tabs .tab {
  font-size: 15px;
  height: 100%;
  flex: 0 0 25%;
  max-width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-bottom: 1px solid #e7e7e7;
}

.videoPlayerSidebar .tabs .tab.true {
  font-weight: 600;
  border-bottom: 3px solid #464b4e;
}

.videoPlayerSidebar .content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 7% 40px 0 40px;
}

.videoPlayerSidebar .content .profile {
  display: flex;
  margin-bottom: 5%;
}

.videoPlayerSidebar .content .profile .user {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.videoPlayerSidebar .content .profile .texts {
  width: 0;
  flex: 1;
  margin-left: 10px;
}

.videoPlayerSidebar .content .profile .texts .headTitle {
  width: 100%;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.videoPlayerSidebar .content .profile.true .texts .headTitle {
  white-space: unset;
  overflow: unset;
  text-overflow: unset;
}

.videoPlayerSidebar .content .playListTitle {
  width: 100%;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 6px;
  margin: 20px 0;
}

.videoPlayerSidebar .content .desc {
  line-height: 1.6;
  font-size: 16px;
  margin: 4px;
}

.videoPlayerSidebar .content .videoListWrap {
  height: 0 !important;
  flex: 1;
}

.videoPlayerSidebar .content .videoListWrap .videoList {
  display: flex;
  flex-direction: column;
}

.videoPlayerSidebar .content .videoListWrap .videoList .vidItm {
  margin-bottom: 25px;
  height: fit-content;
  flex: 0 0 100px;
  display: flex;
  margin-bottom: 20px;
}

.videoPlayerSidebar .content .videoListWrap .videoList .vidItm .vhSmall {
  height: 100px;
  width: fit-content;
  position: relative;
  border: 1px solid #e7e7e7;
}

.videoPlayerSidebar
  .content
  .videoListWrap
  .videoList
  .vidItm
  .vhSmall
  .vhHolder {
  height: 100%;
  width: auto;
}

.videoPlayerSidebar
  .content
  .videoListWrap
  .videoList
  .vidItm
  .vhSmall
  .videoThumb {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.videoPlayerSidebar .content .videoListWrap .videoList .vidItm .textContent {
  flex: 1;
  padding: 4px 4px 4px 16px;
}

.videoPlayerSidebar
  .content
  .videoListWrap
  .videoList
  .vidItm
  .textContent
  .cardTitle {
  font-size: 14px;
  font-weight: 600;
}

.videoPlayerSidebar
  .content
  .videoListWrap
  .videoList
  .vidItm
  .textContent
  .cardDesc {
  font-size: 12px;
}

.videoPlayerSidebar .footer {
  height: 70px;
  border-top: 1px solid #e7e7e7;
  display: flex;
}

.videoPlayerSidebar .footer .channel {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 40px;
  font-size: 19px;
  font-weight: 500;
  border-right: 1px solid #e7e7e7;
}

.videoPlayerSidebar .footer .channelList {
  width: 0;
  flex: 1;
}

.videoPlayerSidebar .footer .channelList > div {
  display: flex;
  padding: 0 20px;
  align-items: center;
}

.videoPlayerSidebar .footer .channelList > div::after {
  content: "";
  height: 100%;
  flex: 0 0 1px;
}

.videoPlayerSidebar .footer .channelList > div .user {
  flex: 0 0 45px;
  height: 45px;
  border-radius: 50%;
  margin-right: 20px;
}

.videoPlayerSidebar .footer .channelList > div .user.true {
  height: 55px;
  flex: 0 0 55px;
}

@keyframes sideBarAnimate {
  from {
    max-width: 0%;
  }

  to {
    max-width: 600px;
  }
}

@keyframes sideBarAnimateR {
  from {
    max-width: 600px;
  }

  to {
    max-width: 0;
  }
}

.mobile-layout .videoPlayerSidebar {
  background: white;
  flex: unset;
  border: none;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.mobile-layout .videoPlayerSidebar .tabs {
  height: 50px;
  background: white;
}

.mobile-layout .videoPlayerSidebar .tabs .tab {
  font-size: 14px;
}

.mobile-layout .videoPlayerSidebar .content {
  padding: 20px 30px 0 30px;
  overflow: auto;
  display: unset;
}

.mobile-layout .videoPlayerSidebar .content .videoListWrap {
  height: 100% !important;
}

.mobile-layout .videoPlayerSidebar .content .videoListWrap .videoList {
  position: relative !important;
  display: unset;
}

.mobile-layout .videoPlayerSidebar .content .videoListWrap .videoList .vidItm {
  display: flex;
  flex-direction: column;
}

.mobile-layout
  .videoPlayerSidebar
  .content
  .videoListWrap
  .videoList
  .vidItm
  .textContent {
  padding: 4px;
}

.mobile-layout .videoPlayerSidebar .footer {
  height: 55px;
}

.mobile-layout .videoPlayerSidebar .footer .btnPower {
  width: 55px;
}

.mobile-layout .videoPlayerSidebar .footer .btnPower img {
  height: 30px;
}

.mobile-layout .videoPlayerSidebar .footer .channel {
  font-size: 14px;
}
