.containerStyle{
    padding:0px 22px;
    overflow-y: scroll;
    height: 80vh;
}
.inputCardWithLabel{
    // padding:10px 22px;
    padding-top: 20px;
}
.breadcrumb{
    text-align: center;
}
.labelText{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    color: #343C5B;
}
.inputStyle{
    border: 1px solid #e7e7e7;
    display: flex;
    border-radius: 20px;
    margin-top: 10px;
    input{
        width: 100%;
        border: none;
        height: 70px;
        margin: 7px;
        font-size: 20px;
        padding-left: 10px;
        font-weight: bold;
        &::placeholder{
            
            opacity: 0.5;
        }
    }
   div{
    display: flex;
    justify-content: center;
    align-items: center;
    // border:1px solid #e7e7e7;
    border-radius: 20%;
    div{
        border:1px solid #e7e7e7;
        border-radius: 20px;
        padding: 5px 10px;
    }
   }
}
.percentageWrapper{
    display: flex;
    // justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    div{
        border: 1px solid #e7e7e7;
        border-radius: 10px;
        padding:  8px 20px;
        margin-right: 20px;
    }
}

.feesCard{
    border: 1px solid #e7e7e7;
    border-radius: 20px;
    margin-top: 10px;
}
.feesRow{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:25px 20px;
    border-bottom: 1px solid #e7e7e7;
}
.halfButtonWrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    div{
        width: 180px;
        height: 70px;
        border: 1px solid #e7e7e7;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
    }
    div:nth-child(2){
        background-color: gray;
        color: white;
    }
}
.inputButton{
    font-weight: bold;
    color: #343C5B;
}