.enterPinVerify {
  // padding: 0px 1.85rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  & > div {
    padding: 0px 1.85rem;
    & > .onholdTitle {
      font-size: 1.1rem;
      font-weight: 500;
      margin: 1.5rem 0;
      margin-bottom: 1rem;
    }
  }

  & > .otpInputWrapperOnhold {
    display: flex;
    width: 20%;
    height: 55px;
    border: 0.5px solid #e5e5e5;

    & > div {
      width: 20%;
      & > input {
        outline: none;
        border: 0.5px solid #e5e5e5;
      }
    }
  }

  & > .btnsContainer {
    border-top: 0.5px solid #e5e5e5;
    padding: 0px 1.85rem;
    & > div {
      border-radius: 25px;
      margin-top: 1rem;
      color: white;
      font-weight: 650;
      font-size: 1rem;
      cursor: pointer;
      padding: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    & > .greenBtn {
      background: #78d0b1;
      border: 0.5px solid #78d0b1;
    }

    & > .redBtn {
      background: #ff5e5e;
      border: 0.5px solid #ff5e5e;
    }

    & > .blueBtn {
      background: #9fd6df;
      border: 0.5px solid #9fd6df;
    }
  }
}

.emailSelection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  & > div {
    & > .title {
      font-size: 1.1rem;
      font-weight: 500;
      margin: 1.5rem 0;
      padding: 1rem 1.85rem;
      margin-bottom: 0.5rem;
    }

    & > .emailInput {
      width: 100%;
      outline: none;
      border: none;
      border-top: 0.5px solid #e5e5e5;
      border-bottom: 0.5px solid #e5e5e5;
      padding: 1.5rem 1.85rem;
    }
  }

  & > .userFind {
    height: -webkit-fill-available;
    overflow-y: scroll;
    margin: 1.5rem 0;
    padding: 0 1.85rem;
    position: relative;

    & > .spinner {
      left: 50%;
      margin-left: -20px;
      top: 50%;
      margin-top: -20px;
      position: absolute;
      z-index: 19 !important;
      animation: loading-bar-spinner 400ms linear infinite;

      & > .spinnerIcon {
        width: 60px;
        height: 60px;
        border: solid 4px transparent;
        border-top-color: #343c5b !important;
        border-left-color: #343c5b !important;
        border-radius: 50%;
      }

      @keyframes loading-bar-spinner {
        0% {
          transform: rotate(0deg);
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }
    }
  }

  & > .btnsContainer {
    border-top: 0.5px solid #e5e5e5;
    padding: 1rem 1.85rem;
    & > div {
      border-radius: 25px;
      margin-top: 1rem;
      color: white;
      font-weight: 650;
      font-size: 1rem;
      cursor: pointer;
      padding: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    & > .greenBtn {
      background: #78d0b1;
      border: 0.5px solid #78d0b1;
    }

    & > .redBtn {
      background: #ff5e5e;
      border: 0.5px solid #ff5e5e;
    }

    & > .blueBtn {
      background: #9fd6df;
      border: 0.5px solid #9fd6df;
    }
  }
}

.eachUserEmailSelection {
  display: flex;
  padding: 1rem 1.85rem;
  cursor: pointer;
  border-bottom: 0.5px solid #e5e5e5;

  & > .userProfile {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 0.5px solid #e5e5e5;

    & > img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  & > .userProfileDetails {
    margin-left: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    & > :nth-child(1) {
      font-size: 1.2rem;
      font-weight: 600;
    }
    & > :nth-child(2) {
      font-size: 1rem;
      font-weight: 400;
    }
  }
}

.emailConfirmation {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  & > div {
    & > .title {
      font-size: 1.1rem;
      font-weight: 500;
      margin: 1.5rem 0;
      padding: 1rem 1.85rem;
      margin-bottom: 0.5rem;
    }
  }

  // .eachUserEmailSelection {
  //   display: flex;
  //   padding: 1rem 0;
  //   cursor: pointer;
  //   border-bottom: 0.5px solid #e5e5e5;

  //   & > .userProfile {
  //     width: 3.5rem;
  //     height: 3.5rem;
  //     border-radius: 50%;
  //     display: flex;
  //     justify-content: flex-start;
  //     align-items: center;
  //     border: 0.5px solid #e5e5e5;

  //     & > img {
  //       width: 100%;
  //       height: 100%;
  //       border-radius: 50%;
  //     }
  //   }

  //   & > .userProfileDetails {
  //     margin-left: 1rem;
  //     display: flex;
  //     flex-direction: column;
  //     justify-content: center;
  //     align-items: flex-start;

  //     & > :nth-child(1) {
  //       font-size: 1.2rem;
  //       font-weight: 600;
  //     }
  //     & > :nth-child(2) {
  //       font-size: 1rem;
  //       font-weight: 400;
  //     }
  //   }
  // }

  & > .btnsContainer {
    border-top: 0.5px solid #e5e5e5;
    padding: 1rem 1.85rem;
    & > div {
      border-radius: 25px;
      margin-top: 1rem;
      color: white;
      font-weight: 650;
      font-size: 1rem;
      cursor: pointer;
      padding: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    & > .greenBtn {
      background: #78d0b1;
      border: 0.5px solid #78d0b1;
    }

    & > .redBtn {
      background: #ff5e5e;
      border: 0.5px solid #ff5e5e;
    }

    & > .blueBtn {
      background: #9fd6df;
      border: 0.5px solid #9fd6df;
    }
  }
}
