
.bondListWrapper{
    padding: 20px 22px;
    border-bottom: 1px solid #e5e5e5;
}
.rowStyle{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 30px;

    div{
        font-weight: 700;
    }
    .rowLeft{
        display: flex;
        align-items: center;
       
    }
}
.rowStyle1{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 15px;
}
.mktImgStyle{
    width: 30px;
    height: 30px;
}

