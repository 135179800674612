.iceIndices {
  flex: 1;
  display: flex;
  flex-direction: column;
  line-height: 1;
  color: $primary-color;
  padding: 30% 40px;
  .title {
    font-size: 40px;
    font-weight: 600;
  }
  .subTitle {
    margin-top: 8px;
    font-size: 20px;
  }
  p {
    margin-top: 15px;
    font-size: 15px;
    line-height: 1.8;
  }
}
