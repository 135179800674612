@import '../../static/scss/colours';
@import '../../static/scss/mixin';
@import '../../static/scss/hamburgers';

.mainNavbar {
  height: 120px;
  padding: 0 8%;
  display: flex;
  align-items: center;
  position: relative;
  .logo {
    height: 45%;
    img {
      height: 100%;
    }
  }
  .navMenu {
    margin-left: auto;
    display: flex;
    align-items: center;
    color: $primary-color;
    .navItem {
      font-size: 19px;
      font-weight: 500;
      margin-left: 50px;
      line-height: 1;
      color: $primary-color;
      height: fit-content;
      text-decoration: none;
      cursor: pointer;
      &:hover {
        font-weight: 600;
        transform: scale(1.05);
      }
      &.true {
        font-weight: bold;
      }
      &.btn {
        color: white;
        transform: unset;
        .btnIn {
          min-width: 180px;
          height: 50px;
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 2rem;
          background: $app-color;
          color: white;
          cursor: pointer;
          @include btn-animate(white);
        }
      }
    }
    .appMenu {
      position: relative;
      .menu {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        border-radius: 10px;
        border: 1px solid $border-color;
        overflow: hidden;
        background: white;
        transition: all 400ms ease;
        transform-origin: top center;
        transform: scale(0);
        z-index: 6;
        .btnIos,
        .btnAndroid {
          height: 60px;
          padding: 0 15%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          color: $text-color;
          margin: 0;
          @include btn-animate($primary-color);
          .icon {
            height: 45%;
            width: 30px;
            object-fit: contain;
          }
          .texts {
            margin-left: 6px;
            .downOn {
              font-size: 11px;
              font-weight: 500;
              line-height: 1;
            }
            .sorce {
              font-size: 14px;
              font-weight: 600;
              line-height: 1;
            }
          }
        }
        .btnLogin {
          height: 50px;
          background: $primary-color;
          font-size: 18px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
          text-decoration: none;
          @include btn-animate(white);
        }
        &.true {
          transform: scale(1);
        }
      }
    }
  }
  .overlay {
    z-index: 5;
    position: fixed;
    left: 0;
    right: 0;
    top: 120px;
    bottom: 0;
    background: $overlay-color;
    .openNav {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      height: fit-content;
    }
  }
  .hamburger {
    display: none;
  }
  .footerBtn {
    display: none;
  }
  @include mdportrait {
    position: sticky;
    top: 0;
    background: white;
    height: 80px;
    flex: 0 0 80px;
    border-bottom: 1px solid $border-color;
    .logo {
      width: 70%;
      height: auto;
      margin: auto;
      img {
        width: 100%;
      }
    }
    .navMenu {
      display: none;
    }
    .hamburger {
      display: flex;
      position: absolute;
      left: 20px;
      top: 0;
      bottom: 0;
      margin: auto;
      height: fit-content;
    }
  }
}

.sidebarMobile {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  .overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: $overlay-color;
    z-index: 0;
  }
  .menuWrap {
    z-index: 1;
    width: 80%;
    height: 100%;
    background: white;
    position: relative;
    display: flex;
    flex-direction: column;
    .header {
      flex: 0 0 80px;
      background: $app-color;
      display: flex;
      align-items: center;
      font-size: 20px;
      font-weight: 600;
      color: white;
      padding: 0 40px;
      .icon {
        height: 18px;
        width: auto;
        margin-left: 8px;
        path {
          fill: white;
        }
      }
    }
    .navMenu {
      height: 0;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      color: $primary-color;
      padding: 40px;
      overflow-y: auto;
      .navItem {
        flex: 0 0 12%;
        font-size: 19px;
        font-weight: 500;
        line-height: 1;
        color: $primary-color;
        height: fit-content;
        text-decoration: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        &:hover {
          font-weight: 600;
          transform: scale(1.05);
        }
        &.true {
          font-weight: bold;
        }
        &.btn {
          color: white;
          transform: unset;
          .btnIn {
            min-width: 180px;
            height: 50px;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 2rem;
            background: $app-color;
            color: white;
            cursor: pointer;
            @include btn-animate(white);
          }
        }
      }
    }
    .btnIos,
    .btnAndroid {
      height: 60px;
      padding: 0 15%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: $text-color;
      margin: 0;
      border: 1px solid $border-color;
      border-radius: 6px;
      width: 100%;
      @include btn-animate($primary-color);
      margin: auto 0;
      .icon {
        height: 45%;
        width: 30px;
        object-fit: contain;
      }
      .texts {
        margin-left: 6px;
        .downOn {
          font-size: 11px;
          font-weight: 500;
          line-height: 1;
        }
        .sorce {
          font-size: 14px;
          font-weight: 600;
          line-height: 1;
        }
      }
    }
  }
}
