.portfolioDashboard {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  .growAssets {
    display: flex;
    flex-direction: column;
    .head {
      height: 65px;
      display: flex;
      .textNBtns {
        flex-grow: 1;
        background: $app-color;
        display: flex;
        align-items: center;
        padding-left: 120px;
        img {
          height: 45%;
          filter: brightness(0) invert(1);
          margin: 0 15px;
        }
        h6 {
          line-height: 1;
          font-size: 20px;
          font-weight: 600;
          margin: 0;
          color: white;
          u {
            cursor: pointer;
          }
        }
      }
      .closeDiv {
        cursor: pointer;
        background: $app-color;
        color: white;
        padding-right: 60px;
        display: none;
        align-items: center;
        font-size: 30px;
      }
    }
    .icingSteps {
      flex-grow: 1;
      display: none;
      .icingStep {
        flex: 0 0 33.333%;
        border-left: 1px solid $vault-border-color;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 30px;
        font-weight: 600;
        color: $primary-color;
        .detailCoin {
          flex: 0 0 30%;
          max-height: 30%;
          display: flex;
          flex-direction: column;
          .img {
            display: flex;
            flex-grow: 1;
            justify-content: center;
            align-items: center;
            font-size: 43px;
            .logo {
              height: 50px;
              margin-right: 10px;
            }
            .logoFull {
              height: 45px;
              margin: 0 auto;
            }
          }
          h6 {
            font-size: 22px;
            text-align: center;
            margin: 10px 0 0 0;
          }
        }
        .issueBond {
          cursor: pointer;
          display: flex;
          flex: 0 0 24%;
          align-items: center;
          font-size: 32px;
          img {
            margin-left: 15px;
            height: 30px;
          }
        }
        &.true {
          background: #f2f2f2;
        }
      }
    }
    &.true {
      flex: 0 0 40%;
      .icingSteps {
        display: flex;
        &.true {
          opacity: 0.7;
        }
      }
      .head {
        .closeDiv {
          display: flex;
        }
      }
    }

    &.opacive {
      opacity: 0.3;
    }
    @include mdportrait {
      flex: 0 0 170px !important;
      .head {
        height: 40px;
        display: flex;
        .textNBtns {
          padding-left: 0;
          img {
            display: none;
          }
          h6 {
            display: none;
          }
        }
        .closeDiv {
          cursor: pointer;
          background: $app-color;
          color: white;
          padding-right: 30px;
          display: none;
          align-items: center;
          font-size: 25px;
        }
      }
      .icingSteps {
        flex-grow: 1;
        display: none;
        .icingStep {
          font-size: 14px;
          .detailCoin {
            flex: 0 0 30%;
            max-height: 30%;
            .img {
              font-size: 14px;
              .logo {
                height: 18px;
                margin-right: 6px;
              }
              .logoFull {
                height: 17px;
                margin: 0 auto;
              }
            }
            h6 {
              font-size: 13px;
              margin: 0;
            }
          }
          .issueBond {
            font-size: 12px;
            justify-content: center;
            img {
              margin-left: 6px;
              height: 10px;
            }
          }
        }
      }
    }
  }
}
