.icedIndex .icedContainer .mainContent .content-section.funds {
  padding: 0;
  .fundsComponent {
      flex: 1;
      display: flex;
      .leaderboard-drawer{
          width: 550px;
          animation: icceSideAnimate 800ms;
      }
  }
 
}
