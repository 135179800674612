.pb-p-25 {
  padding-bottom: 25%;
}
.disable {
  opacity: 0.2;
  cursor: wait !important;
}
.disabledValue {
  opacity: 0.4;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: $primary-color;
}

.growAssets {
  display: flex;
  flex-direction: column;
  position: relative;
  display: flex;
  .head {
    height: 65px;
    display: flex;
    position: relative;
    .textNBtns {
      flex-grow: 1;
      background: $app-color;
      display: flex;
      align-items: center;
      padding-left: 120px;
      img {
        height: 45%;
        filter: brightness(0) invert(1);
        margin: 0 15px;
      }
      h6 {
        line-height: 1;
        font-size: 20px;
        font-weight: 600;
        margin: 0;
        color: white;
        u {
          cursor: pointer;
        }
      }
    }
  }
  .closeDiv {
    cursor: pointer;
    position: absolute;
    color: white;
    right: 20px;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    font-size: 25px;
  }
}

.headTabsWrapper {
  height: 60px;
  padding-right: 60px;
  border-bottom: 1px solid $vault-border-color;
  display: flex;
  position: relative;
  .headTab {
    align-items: center;
    display: flex;
    width: 150px;
    padding: 0 30px;
    border-right: 1px solid $vault-border-color;
    opacity: 0.5;
    font-size: 12px;
    position: relative;
    .text {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .bt-close {
      cursor: pointer;
      position: absolute;
      font-size: 18px;
      top: 8px;
      right: 8px;
      transform: rotate(45deg);
      line-height: 0.7;
      color: gray;
    }
    &.true {
      width: unset;
      opacity: 1;
      font-size: 15px;
      font-weight: 600;
    }
  }
  .tab-ice {
    border-left: 1px solid $vault-border-color;
    min-width: 60px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    position: absolute;
    img {
      height: 50%;
    }
  }
}
