.icePayouts {
  position: fixed;
  top: 0;
  bottom: 65px;
  right: 0;
  left: 0;
  background: white;
  z-index: 1;
  border-bottom: 1px solid $vault-border-color;
  display: flex;
  flex-direction: column;
  .logoMain {
    width: 60%;
    height: 44px;
    margin: 18px auto 5px auto;
  }
  .poweredBy {
    display: flex;
    color: #186ab4;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    font-weight: bold;
    img {
      margin-left: 8px;
      height: 18px;
    }
  }
  .payoutsHead {
    width: 100%;
    margin-top: 3%;
    height: 25%;
    background: rgba(231, 231, 231, 0.27);
    display: flex;
    align-items: center;
    overflow-y: auto;
    &::before,
    &::after {
      content: '';
      flex: 0 0 1px;
      height: 100%;
    }
    .item {
      margin: 0 30px;
      color: $primary-color;
      .value {
        font-size: 26px;
        line-height: 1;
        font-weight: bold;
      }
      .label {
        font-size: 12px;
        white-space: nowrap;
      }
    }
  }
  .buttons {
    margin: 10px 0;
    display: flex;
    padding: 8px 0;
    .btn-filter {
      cursor: pointer;
      border: 1px solid $vault-border-color;
      color: $primary-color;
      font-weight: 600;
      width: 0;
      flex: 0 0 30.3%;
      margin: 0 1.5%;
      line-height: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      padding: 10px 0;
      &:last-child {
        margin: 0 1.5%;
      }
    }
    &.true {
      margin-bottom: 10px;
    }
    &.sub {
      margin-top: 0;
      margin-bottom: 10px;
      padding-top: 0;
    }
  }
  .iceEarningList {
    .earningList {
      display: flex;
      flex-direction: column;
      padding: 0;
      .day {
        font-size: 14px;
        font-weight: 600;
        padding: 0 20px;
        margin: 10px 0;
      }
      .transaction {
        cursor: pointer;
        margin: 8px 20px;
        justify-content: space-between;
        display: flex;
        padding: 24px 30px;
        border: 1px solid $vault-border-color;
        align-items: center;
        h6 {
          display: flex;
          align-items: center;
          margin: 0;
          font-size: 16px;
          line-height: 1;
          img {
            height: 16px;
            margin-right: 8px;
          }
          .value {
            display: block;
          }
          .expand {
            display: none;
            text-decoration: underline;
            cursor: pointer;
          }
        }
        &.true {
          h6 {
            .value {
              display: none;
            }
            .expand {
              display: block;
            }
          }
        }
        &:hover {
          background: whitesmoke;
        }
      }
    }
  }
}
