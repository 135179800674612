.candleSeriesChart {
  height: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  .comingSoon {
    height: 80%;
    width: 40%;
    margin: auto;
    object-fit: contain;
  }
  .chartContainer {
    height: 0;
    flex: 1;
  }
  .logo {
    position: absolute;
    bottom: 50px;
    right: 20px;
    height: 30px;
    width: auto;
    z-index: 1;
  }
}
