.chatsIoBots {
  flex: 1;
  display: flex;
  flex-direction: column;
  .btnsScrlWrapper {
    height: 90px !important;
    .btnsScrlList {
      display: flex;
      align-items: center;
      display: flex;
      padding: 0 40px;
      .btnSwitch {
        height: 45px;
        cursor: pointer;
        border: 1px solid $border-color;
        flex: 0 0 28%;
        margin-right: 8%;
        font-size: 13px;
        display: flex;
        font-weight: 500;
        justify-content: center;
        align-items: center;
        transition: all 400ms ease;
        &.true {
          border: 3px solid $border-color;
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
  }
  .actionBtns {
    padding: 10px 40px;
    .head {
      font-size: 16px;
      font-weight: 500;
      text-decoration: underline;
      margin-bottom: 15px;
    }
    .botOptionWrapper {
      height: 100px !important;
      .botOptionList {
        margin: 5px 0;
        display: flex;
        .option {
          cursor: pointer;
          flex: 0 0 23%;
          margin-right: 2.66%;
          border: 1px solid $border-color;
          border-radius: 4px;
          height: 90px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          img {
            height: 40%;
            width: 40%;
            object-fit: contain;
          }
          span {
            text-align: center;
            line-height: 1;
            font-size: 13px;
            margin-top: 4px;
          }
          * {
            transition: all 400ms ease;
          }
          &.true {
            border: 4px solid $border-color;
          }
          &.n-5 {
            flex: 0 0 17.8%;
          }
          &:hover {
            background: whitesmoke;
            * {
              transform: scale(1.15);
            }
          }
        }
      }
    }
  }
}
