.landingPage {
  flex: 1;
  display: flex;
  flex-direction: column;
  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .logo {
      width: 45%;
      height: auto;
    }
    .btns {
      margin-top: 3%;
      width: 30%;
      height: 60px;
      display: flex;
      justify-content: space-between;
      .btnRegister,
      .btnAdmin {
        flex: 0 0 49%;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 22px;
        font-weight: 500;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        &::before {
          content: '';
          background-color: white;
          width: 100%;
          height: 40px;
          position: absolute;
          left: -100%;
          transform: rotateZ(45deg);
        }
        &:hover {
          &::before {
            transition: all 0.4s ease;
            left: 100%;
          }
        }
      }
      .btnRegister {
        background: white;
        border: 1px solid $primary-color;
        color: $primary-color;
      }
      .btnAdmin {
        background: $primary-color;
      }
    }
  }
  .footer {
    background: $primary-color;
    height: 8%;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      height: 40%;
      width: auto;
    }
  }
}
