.trackerImageAdd {
  //   width: 40vw;
  max-width: 800px;
  border-left: 0.5px solid #e5e5e5;
  position: absolute;
  bottom: 0;
  right: 0;
  top: 0;
  background-color: white;
  z-index: 3;
  padding: 3rem;
  //   overflow-y: scroll;

  & > .trackerImageAddHead {
    display: flex;
    justify-content: space-between;
    font-size: 1.6rem;
    font-weight: 700;
    align-items: center;

    & > :nth-child(2) {
      width: 35px;
      height: 35px;
      background: #e73625;
      border: 0.5px solid #e7e7e7;
      border-radius: 5px;
      padding: 5px;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      font-size: 1.1rem;
    }
  }

  & > .trackerImageContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 2rem;
    overflow-y: scroll;
    height: calc(85% - 40px);

    & > div {
      width: 32vh;
      height: 32vh;
      border: 0.5px solid #e5e5e5;
      border-radius: 5px;

      & > img {
        width: 100%;
        height: 100%;
      }
    }
  }

  & > .trackerUploadingContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 2rem;
    overflow-y: scroll;
    height: calc(85% - 40px);
    & > .uploadingDiv {
      width: 30vw;
      height: 30vw;
      border-radius: 5px;
      border: 0.5px solid #e5e5e5;

      & > img {
        width: 100%;
        height: 100%;
      }
    }
    & > .inputDiv {
      background: #ffffff;
      border: 0.5px solid #e5e5e5;
      border-radius: 5px;
      font-size: 1.2rem;
      font-weight: 650;
      padding: 1rem;
    }
  }

  & > .uploadingBtn {
    background: #9fd6df;
    border-radius: 10px;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: white;
    font-size: 1.2rem;
    font-weight: 650;
    margin-top: 2rem;
  }
}
