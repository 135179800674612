.contracts {
  width: 100%;
  overflow-y: hidden;
  position: absolute;
  inset: 0;

  .contract-container {
    width: 100%;
    height: 100%;
    color: #343c5b;

    .vaultss-navbar {
      width: 100%;
      height: 8%;
      border-bottom: 0.5px solid #e7e7e7;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 4rem 1rem 3rem;

      .vaultss-icon {
        width: 10%;
        height: 100%;
        // border: 1px solid red;
        img {
          width: 100%;
          height: 100%;
        }
      }

      .search-vaultss {
        width: 55%;
        height: 100%;
        border: 0.5px solid #ededed;
        // background: rgba(237, 237, 237, 0.37);
        background: white;
        border-radius: 5px;
        padding: 0.5rem 2rem;
        display: flex;
        align-items: center;
        position: relative;
        .all {
          width: 32%;
          height: 100%;
          border-right: 3px solid #e7e7e7;
          display: flex;
          align-items: center;
          font-size: 1rem;
          color: #343c5b;
          font-weight: 700;
          opacity: 0.25;
          /* grid-gap: 1rem; */
          /* gap: 1rem; */
          justify-content: space-between;
          padding-right: 15px;
          p {
            margin-bottom: 0;
          }
          .arroww {
            display: flex;
            align-items: center;
            img {
              width: 15px;
              height: 15px;
              cursor: pointer;
            }
          }
        }
        .im {
          width: 100%;
          height: 100%;
          // padding-left: 1rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 1rem;
          color: #343c5b;
          font-weight: 700;
          // opacity: 0.25;
          gap: 1rem;
          input {
            border: none;
            font-size: 1rem;
            color: #343c5b;
            font-weight: 700;
            background: transparent;
          }
          img {
            width: 16px;
            height: 16px;
          }
        }
      }

      .right-top {
        width: 30%;
        height: 100%;
        display: flex;
        .divv {
          // width: 50%;
          height: 100%;
          display: flex;
          gap: 1rem;
          align-items: center;
          p {
            margin-bottom: 0;
            font-size: 0.9rem;
            color: #343c5b;
            font-weight: 600;
          }
        }
      }
    }

    .w-space {
      width: 100%;
      height: 4%;
      padding: 0 4rem 0 3rem;
      border-bottom: 0.5px solid #e7e7e7;
      .optionss {
        width: 30%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .optionss-divider {
          display: flex;
          width: 80%;
          height: 100%;
          align-items: center;
          justify-content: space-between;
          padding: 5px 0;
          .each-option-selected {
            background: rgba(229, 229, 229, 0.25);
            font-weight: 600;
            padding: 10px 1rem;
            border-radius: 5px;
          }
          .each-option {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            p {
              margin-bottom: 0;
              font-size: 0.9rem;
            }
            div {
              display: flex;
              gap: 5px;
              transition: all ease 0.3s;
              cursor: pointer;
              &:hover {
                scale: 1.1;
              }
            }
          }
        }
        .last-option {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          div {
            display: flex;
            gap: 5px;
            transition: all ease 0.3s;
            font-size: 0.65rem;
          }
        }
      }
    }

    .header-data {
      padding: 2rem 0 2rem 3rem;
      display: flex;
      flex-direction: column;
      height: 8.5rem;
      justify-content: center;

      .contract-value {
        font-size: 1.75rem;
        font-weight: 700;
      }
    }

    .boxes-container {
      width: 100%;
      height: 7rem;
      padding: 2rem 3rem;
      display: flex;
      justify-content: space-between;

      .all-boxes {
        width: 60%;
        display: flex;
        gap: 1.5rem;

        .each-box {
          border: 0.5px solid #eeeeee;
          border-radius: 5px;
          display: flex;
          justify-content: space-around;
          align-items: center;
          padding: 1.5rem 2rem;
          gap: 2rem;
          cursor: pointer;
          div {
            font-size: 0.8rem;
            font-weight: 700;
          }
          img {
            width: 10px;
            height: 10px;
          }
        }
      }

      .search-box {
        width: 23%;
        display: flex;
        .each-box {
          border: 0.5px solid #eeeeee;
          border-radius: 5px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 1.5rem 2rem;
          width: 100%;
          cursor: pointer;
          div {
            font-size: 0.9rem;
            font-weight: 200;
          }
          img {
            width: 15px;
          }
        }
      }
    }

    .table-header {
      display: flex;
      padding: 0 3rem;
      div {
        width: 15%;
      }
    }

    .table-data {
      width: 100%;
      padding: 1.5rem 3rem 0.5rem 3rem;
      height: calc(88% - 17rem);

      .scroll-div {
        width: 100%;
        height: 100%;
        overflow-y: scroll;

        .table-card {
          border: 0.5px solid #e5e5e5;
          border-radius: 10px;
          width: 100%;
          display: flex;
          height: 6rem;
          align-items: center;
          margin-top: 1.5rem;
          transition: all ease 0.2s;
          cursor: pointer;

          .date-val {
            width: 15%;
            font-size: 1rem;
            font-weight: 700;
          }

          .hash-btn {
            background: #343c5b;
            border-radius: 5px;
            color: #ffffff;
            padding: 0.5rem 2rem;
            font-size: 1.1rem;
            font-weight: 650;
            cursor: pointer;
            transition: all ease 0.2s;
            &:hover {
              transform: scale(1.07);
            }
          }

          &:hover {
            background: #e5e5e5;
          }
        }
      }
    }
  }
}
