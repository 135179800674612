.marketWrapper{
    padding:22px;
    border-bottom: 1px solid #e5e5e5;
}

.marketRow{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
}
.marketRowSmall{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    padding-top: 5px;
}