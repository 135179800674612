@import '../../static/scss/colours';
@import '../../static/scss/mixin';

.investorPage {
  height: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  .navbar {
    background: #e5f3e5;
  }
  .explore {
    padding: 20px 0;
    background: linear-gradient(
      0deg,
      #0000 0%,
      #0000 30%,
      #e5f3e5 30%,
      #e5f3e5 100%
    );
    .subTitle {
      padding: 0 8%;
      font-size: 30px;
    }
    .title {
      padding: 0 8%;
      font-size: 60px;
      font-weight: bold;
    }
    .cardListWrap {
      display: flex;
      padding-top: 20px;
      .cardList {
        width: 0;
        flex: 1;
        overflow-x: auto;
        display: flex;
        padding: 0 8%;
        .card {
          flex: 0 0 23%;
          margin-right: 2%;
          height: 230px;
          border: 1px solid $border-color;
          background: white;
          border-radius: 20px;
          position: relative;
          .icon {
            position: absolute;
            bottom: 30px;
            left: 30px;
            height: 25%;
          }
        }
      }
    }
  }
  .trendingAssets {
    flex: 1;
    padding-bottom: 70px;
    display: flex;
    flex-direction: column;
    .title {
      padding: 0 8%;
      font-size: 30px;
      font-weight: 600;
    }
    .cardListWrap {
      height: 0;
      flex: 1;
      display: flex;
      padding-top: 20px;
      .cardList {
        width: 0;
        flex: 1;
        overflow-x: auto;
        display: flex;
        padding: 0 8%;
        .carouselCard {
          flex: 0 0 30%;
          margin-right: 3%;
          border: 1px solid $border-color;
          border-radius: 20px;
          background-color: white;
          display: flex;
          overflow: hidden;
          .content {
            flex: 1;
            padding: 20px 30px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .logo {
              width: 100%;
              height: auto;
              margin-bottom: 6px;
            }
            .cardSubTitle {
              font-size: 16px;
              margin-bottom: 6px;
            }
            .rate {
              font-size: 22px;
              font-weight: 600;
              small {
                margin-left: 4px;
                font-size: 13px;
              }
            }
            .btnsCard {
              margin: 5px 0;
              height: 30px;
              display: flex;
              .btnHyd,
              .btnCrypto {
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                font-weight: 500;
                text-decoration: none;
                color: white;
                padding: 0 15px;
                @include btn-animate(white);
                white-space: nowrap;
                .icon {
                  height: 18px;
                  width: 18px;
                  margin-left: 6px;
                }
              }
              .btnHyd {
                margin-right: 8px;
                background: #f2a900;
              }
              .btnCrypto {
                background: $primary-color;
              }
            }
          }
          .bg {
            height: 100%;
            width: auto;
            object-fit: contain;
          }
        }
      }
    }
  }
}
