.getStartedPage {
  flex: 1;
  display: flex;
  position: relative;
  .bgImage {
    flex: 0 0 40%;
    object-fit: contain;
    background-size: cover;
    background-position: right;
  }
  .signupSidebar {
    flex: 0 0 40%;
    border-right: 1px solid $border-color;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 0 6%;
    .title {
      font-size: 34px;
      font-weight: 600;
    }
    .step {
      font-size: 20px;
      font-weight: 500;
      opacity: 0.4;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .tick {
        height: 22px;
        width: 22px;
        display: none;
      }
      &.done {
        opacity: 1;
        .tick {
          display: block;
        }
      }
      &.active {
        opacity: 1;
        font-weight: 600;
      }
    }
  }
  .stepWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .assetLogo {
      width: 40%;
      max-height: 200px;
      transition: all ease 400ms;
      object-fit: contain;
      &:hover {
        transform: scale(1.05);
      }
    }
  }
  .stepForm {
    position: relative;
    .stepDesc {
      font-size: 20px;
      margin: 1.5% 0 3% 0;
      width: 45%;
      text-align: center;
    }
    .group {
      border: 1px solid $border-color;
      width: 45%;
      height: 70px;
      margin: 2% 0;
      border-radius: 6px;
      overflow: hidden;
      position: relative;
      display: flex;
      .input {
        width: 0;
        flex: 1;
        height: 100%;
        border: none;
        outline: none;
        padding: 0 40px;
      }
      .circle {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 30px;
        margin: auto;
        height: 30%;
      }
      .icon {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 30px;
        margin: auto;
        height: 40%;
      }
      .domain {
        display: flex;
        align-items: center;
        padding: 0 20px;
        font-weight: 600;
        border-left: 1px solid $border-color;
        font-size: 19px;
      }
      .eye {
        cursor: pointer;
        position: absolute;
        right: 20px;
        top: 0;
        bottom: 0;
        margin: auto;
      }
      &.true,
      &.false {
        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          width: 15px;
        }
      }
      &.true {
        &::before {
          background-color: $primary-color;
        }
      }
      &.false {
        &::before {
          background-color: $primary-red;
        }
      }
    }
    .otp-input-wrapper {
      width: 45%;
      margin: 10px 0;
      height: 70px;
      display: flex;
      justify-content: space-between;
      > div {
        flex: 0 0 15%;
        .otp-input {
          width: 100% !important;
          height: 100%;
          font-size: 20px;
        }
      }
    }

    .otp-input-wrapper-onhold {
      width: 100%;
      justify-content: space-between;
      > div {
        width: 20%;
        height: 80px;
        .otp-input {
          width: 100% !important;
          height: 100%;
          border: none;
          background: #ffffff;
          box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
          border-radius: 5px;
          font-size: 28px;
        }
      }
    }
    .userDetail {
      width: 45%;
      border: 1px solid $border-color;
      display: flex;
      flex-direction: column;
      .user {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 8% 0;
        border-bottom: 1px solid $border-color;
        .profileImg {
          height: 110px;
          width: 110px;
          border-radius: 50%;
          margin-bottom: 20px;
        }
        .name {
          line-height: 1;
          font-size: 22px;
          font-weight: 600;
          margin-bottom: 10px;
        }
        .email {
          font-size: 19px;
          line-height: 1;
        }
      }
      .bio {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 4% 0;
        .bioDesc {
          font-size: 17px;
          padding: 3% 8%;
          text-align: center;
        }
      }
      .invalid {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 4% 0;
        color: #343c5b;
        .profileImg {
          height: 110px;
          width: 110px;
          margin-bottom: 20px;
        }
        .mainText {
          width: 80%;
          font-size: 21px;
          font-weight: 600;
          text-align: center;
        }
        .tryAgain {
          font-size: 17px;
        }
      }
    }
    .btnSingle {
      width: 35%;
      background: $primary-color;
      color: white;
      font-size: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      position: relative;
      overflow: hidden;
      &::before {
        content: '';
        background-color: white;
        width: 100%;
        height: 40px;
        position: absolute;
        left: -100%;
        transform: rotateZ(45deg);
      }
      &:hover {
        border: 1px solid $primary-color;
        background: $primary-color;
        color: white;
        &::before {
          transition: all 0.4s ease;
          left: 100%;
        }
      }
    }
    .btns {
      margin-top: 4%;
      width: 45%;
      height: 70px;
      display: flex;
      justify-content: space-between;
      .btnBig,
      .btnNext {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $border-color;
        border-radius: 6px;
        font-size: 22px;
        font-weight: 500;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        &::before {
          content: '';
          background-color: white;
          width: 100%;
          height: 40px;
          position: absolute;
          left: -100%;
          transform: rotateZ(45deg);
        }
        &:hover {
          border: 1px solid $primary-color;
          background: $primary-color;
          color: white;
          &::before {
            transition: all 0.4s ease;
            left: 100%;
          }
        }
      }
      .btnBig {
        flex: 1;
        margin-right: 2%;
        &.false {
          opacity: 0.4;
          &:hover {
            border: 1px solid $border-color;
            background: unset;
            color: unset;
            &::before {
              content: none;
            }
          }
        }
      }
      .btnNext {
        flex: 0 0 33%;
        background: $primary-color;
        color: white;
        &.false {
          opacity: 0.4;
          &:hover {
            border: 1px solid $border-color;
            background: $primary-color;
            color: white;
            &::before {
              content: none;
            }
          }
        }
        &.w50 {
          flex: 0 0 49%;
        }
        &.w60 {
          flex: 0 0 60%;
        }
      }
    }
    .btnLink,
    .resend {
      cursor: pointer;
      position: absolute;
      bottom: 60px;
      &:hover {
        text-decoration: underline;
      }
    }
    .resend {
      span {
        font-weight: 600;
        opacity: 0.5;
        cursor: pointer;
      }
      &:hover {
        text-decoration: none;
        span {
          text-decoration: underline;
        }
      }
      &.true {
        span {
          opacity: 1;
        }
      }
    }
  }
  .selectProcess {
    position: relative;
    padding-bottom: 40px;
    .desc {
      text-align: center;
      width: 50%;
      font-size: 1.05vw;
      margin: 5% 0;
    }
    .actions {
      width: 35%;
      display: flex;
      flex-direction: column;
      .btnAction {
        cursor: pointer;
        height: 75px;
        border: 1px solid $primary-color;
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 19px;
        font-weight: 600;
        &.inv {
          background: $primary-color;
          color: white;
        }
        position: relative;
        overflow: hidden;
        &::before {
          content: '';
          background-color: white;
          width: 100%;
          height: 40px;
          position: absolute;
          left: -100%;
          transform: rotateZ(45deg);
        }
        &:hover {
          border: 1px solid $primary-color;
          background: $primary-color;
          color: white;
          &::before {
            transition: all 0.4s ease;
            left: 100%;
          }
        }
      }
    }
    .footerLogin {
      cursor: pointer;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      border-top: 1px solid $border-color;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 21px;
      svg {
        margin-left: 10px;
        height: 30px;
        width: 30px;
      }
      &:hover {
        background: whitesmoke;
        zoom: 1.05;
      }
    }
  }
  .loadingAnim {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: #ffffff7e;
    display: flex;
  }
  .passwordReq {
    width: 40%;
    max-width: 650px;
    .contents {
      display: flex;
      flex-direction: column;
      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
        font-size: 20px;
        .circle {
          height: 15px;
          width: 15px;
        }
      }
      p {
        font-size: 15px;
        line-height: 2;
      }
      .stepTitle {
        font-size: 24px;
        font-weight: 600;
        line-height: 2;
      }
      .itemStep {
        font-size: 15px;
        line-height: 2;
        a {
          color: $primary-color;
          font-weight: 600;
          cursor: pointer;
        }
      }
    }
  }
}
