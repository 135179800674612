.breadcrumb1 {
  width: 370px;
  padding-top: 12px;
  padding-left: 1.85rem;
  cursor: pointer;
  background-color: white;
}
.breadCrumbText1:hover {
  font-weight: 700 !important;
  transform: scale(1.05);
  transition: all 0.3s ease-in-out;
}
.breadsubText1:hover {
  font-weight: 700 !important;
  transform: scale(1.05);
  transition: all 0.3s ease-in-out;
}

.selectmaster {
  display: flex;
  justify-content: flex-start;
  margin-left: 20px;
  height: 100%;
  width: 90%;
  .sub-card {
    width: 100%;
    display: table-cell;
    vertical-align: middle;
    padding-top: 95px;
    .selecthead {
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      margin-left: 32px;
    }
    .search-card {
      display: flex;
      flex-direction: column;
      width: 90%;
      border: 0.5px solid #eeeeee;
      margin: 20px;
      border-radius: 15px;
      height: 90%;
      overflow-y: scroll;
      position: relative;
      .searchboxasset {
        position: sticky;
        display: block;
        top: 0;
        left: 0;
        right: 0;
        background: white;
        height: 60px;
        border-bottom: 0.5px solid #eeeeee;
        padding: 20px;
        .search {
          font-weight: 400;
          font-size: 15px;
          line-height: 18px;
          color: #343c5b;
          border: none;
          width: 100%;
        }
        .search::-webkit-input-placeholder {
          opacity: 0.25;
        }
        .search::placeholder {
          opacity: 0.25;
        }
      }
      .assetlistcard {
        overflow-y: scroll;
        height: max-content;
      }
      .coin-box {
        display: flex;
        padding: 20px;
        border-bottom: 0.5px solid #eeeeee;
        cursor: pointer;
        .first-div {
          height: 30px;
          width: 30px;
          border-radius: 50%;
        }
        .second-div {
          margin-left: 15px;
          height: 30px;
          width: 150px;
        }
      }
      .coin-box:hover {
        background-color: #f5f5f5;
        transform: scale(1.05);
        transition: all 0.3s ease-in-out;
      }
      .coin-Image {
        height: 25px;
        width: 25px;
      }
      .coin-Title {
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
        margin: 5px 0 0 15px;
      }
    }
  }
}
