.bondWrapper{
    // background-color: lightgray;
    border-bottom: solid 1px #e7e7e7;
    margin-bottom: 10px;
    width: 100vw;
    .dataRow{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 20px;
        .headerLeft{
            display: flex;
            flex-direction: row;
            align-items: center;
            img{
                width: 30px;
                height: 30px;
            }
            div{
                padding-left: 7px;
            }
        }
    }
    .dataRow1{
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: solid 0.5px #e7e7e7;
        padding: 20px 0px;
    }
    .dataRow2{
        display: flex;
        justify-content: space-between;
        align-items: center;
     
    }
    .title{
        font-weight: 700;
        font-size: 18px;
    }
    .subTitle{
        font-size: 12px;
    }
    .dataRight{
        text-align: right;
    }
    .roundButton{
        // padding:5px;
        border:solid 1px #e7e7e7;
        border-radius: 20px;
        margin-right:10px;
        font-weight: bold;
        font-size: 12px;
    }
}