.markets {
  flex: 1;
  padding: 50px 80px;
  height: 60vh;
  display: flex;
  flex-direction: column;
  .earn-intrest-controll {
    width: 100%;
    flex: 0 0 100px;
    height: 100px;
    margin-bottom: 30px;
    > div {
      display: flex;
      flex-direction: row;
      .tab-item {
        opacity: 0.35;
        cursor: pointer;
        display: flex;
        flex: 0 0 32%;
        margin-right: 2%;
        height: 100%;
        background-color: white;
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.08);
        padding: 0 20px;
        align-items: center;
        justify-content: flex-start;
        border: 1px solid #e7e7e7;
        .coin-img {
          height: 35px;
          width: 35px;
        }
        .text-content {
          width: 0;
          flex: 1;
          margin-left: 25px;
          flex: 1;
          h5 {
            line-height: 1;
            font-size: 22px;
            margin-bottom: 0.2rem;
            color: $primary-color;
            font-weight: 600;
            margin-bottom: 2px;
            display: flex;
            justify-content: space-between;
            span {
              margin-right: 30px;
            }
          }
          .desc {
            width: 100%;
            line-height: 1;
            font-size: 12px;
            color: $primary-color;
            margin: 0;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
        &:hover {
          opacity: 0.8;
          border-width: 4px;
          padding: 0 17px;
        }
        &.true {
          opacity: 1;
          border-width: 4px;
          padding: 0 17px;
        }
      }
    }
  }
  .pathListScroll {
    flex: 1;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.08);
    > div {
      display: flex;
      flex-direction: column;
      position: relative;
      .headSticky {
        top: 0;
        position: sticky;
        height: 30px;
        flex: 0 0 30px;
        background: #f8f8f8;
        padding: 0 40px;
        display: flex;
        align-items: center;
        line-height: 1;
        font-size: 13px;
        font-weight: 600;
        .asset {
          flex: 0 0 19%;
        }
        .banker {
          flex: 0 0 23%;
        }
        .issuancePrice {
          flex: 0 0 19%;
        }
        .livePrice {
          flex: 0 0 20%;
        }
      }
      .pathItem {
        cursor: pointer;
        flex: 0 0 100px;
        border-bottom: 1px solid #d8d8d8;
        padding: 0 40px;
        display: flex;
        align-items: center;
        > div,
        > a {
          padding-right: 20px;
          font-size: 18px;
          font-weight: 600;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-transform: capitalize;
          color: $primary-color;
          text-decoration: none;
          .coinIcon {
            height: 40px;
            width: 40px;
            object-fit: contain;
            margin-right: 10px;
          }
        }
        .asset {
          flex: 0 0 19%;
        }
        .banker {
          flex: 0 0 23%;
        }
        .issuancePrice {
          flex: 0 0 19%;
        }
        .livePrice {
          flex: 0 0 20%;
        }
        .buttons {
          flex: 0 0 19%;
          display: flex;
          .btnSell,
          .btnBuy {
            flex: 0 0 40%;
            margin-left: 3%;
            padding: 6px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            border-radius: 6px;
            svg {
              margin-left: 6px;
            }
            &:hover {
              filter: contrast(1.4);
            }
          }
          .btnSell {
            background: #cb4d49;
            opacity: 0.3;
            cursor: wait;
          }
          .btnBuy {
            cursor: pointer;
            background: #70c582;
          }
        }
        &:hover {
          background: whitesmoke;
          * {
            zoom: 1.03;
          }
        }
      }
    }
  }
}
