.actionPanelModal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.85);
  z-index: 5;
  display: flex;
  .fabBtn {
    background: white;
    &::after,
    &::before {
      background: $primary-color;
    }
  }
  .sidebar {
    flex: 0 0 440px;
    background: white;
    display: flex;
    flex-direction: column;
    .headTitle {
      padding: 40px;
      font-size: 30px;
      font-weight: 600;
      .logo {
        height: 32px;
        width: auto;
      }
    }
    .menuItm {
      cursor: pointer;
      height: 100px;
      display: flex;
      align-items: center;
      padding: 0 40px;
      color: $primary-color;
      text-decoration: none;
      position: relative;
      .icon {
        height: 24px;
        width: 24px;
        margin-right: 10px;
      }
      .logo {
        height: 25px;
        width: auto;
      }
      .name {
        font-size: 20px;
        font-weight: 500;
      }
      .menuToolTip {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 105%;
        width: 70%;
        height: 85%;
        margin: auto;
        background: white;
        display: none;
        align-items: center;
        padding: 0 25px;
        font-weight: 500;
        &::before {
          content: '';
          top: 0;
          bottom: 0;
          left: -16px;
          margin: auto;
          position: absolute;
          display: block;
          width: 0;
          height: 0;
          border-left: 8px solid transparent;
          border-right: 8px solid white;
          border-bottom: 8px solid transparent;
          border-top: 8px solid transparent;
        }
      }
      &:hover,
      &.true {
        background: #fafafa;
        > * {
          zoom: 1.05;
        }
        .menuToolTip {
          display: flex;
        }
      }
    }
    .goBack {
      margin-top: auto;
      height: 65px;
      background: $primary-color;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 22px;
      font-weight: 600;
      color: white;
    }
  }
  .contentWrapper {
    flex: 1;
    position: relative;
    display: flex;
    .overLayClose {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    .modalCoinAddress {
      z-index: 2;
      width: 420px;
      margin: auto;
      padding: 60px 40px;
      background: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      .headNameLogo {
        display: flex;
        align-items: center;
        .icon {
          height: 55px;
          margin-right: 10px;
        }
        span {
          line-height: 1;
          color: $primary-color;
          font-size: 53px;
          font-weight: bolder;
        }
      }
      .poweredBy {
        margin-top: 6px;
        height: 18px;
      }
      p {
        margin: 50px 40px;
        line-height: 1.8;
        font-size: 15px;
        text-align: center;
      }
      .btnShow {
        cursor: pointer;
        width: 75%;
        height: 60px;
        border: 1px solid $primary-color;
        color: $primary-color;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 22px;
        &:hover {
          background: $primary-color;
          color: white;
        }
      }
      &.true {
        width: 500px;
        .qr-svg {
          margin: 40px 0;
          width: 70%;
          height: auto;
        }
        .wallet-addr {
          width: 90%;
          cursor: pointer;
          border-radius: 4px;
          border: 1px solid $vault-border-color;
          padding: 6px 15px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          span {
            width: 85%;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}
