.mobileApps {
  flex: 1;
  display: flex;
  flex-direction: column;
  .logo-n-title {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .logo {
      height: 200px;
      width: 200px;
      margin-top: 130px;
    }
    .title {
      margin-top: 20px;
      color: $primary-color;
      font-size: 50px;
      font-weight: bold;
    }
    .subTitle {
      display: none;
    }
  }
  .download {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h6 {
      font-size: 27px;
      margin-bottom: 1.5rem;
    }

    .buttons {
      display: flex;
      width: 100%;
      justify-content: center;
      margin-bottom: 80px;
      .btn-app {
        border: $primary-color 1px solid;
        color: $primary-color;
        text-decoration: none;
        border-radius: 6px;
        padding: 5px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40%;
        max-width: 230px;
        img {
          height: 26px;
          margin-right: 15px;
        }
        font-size: 24px;
        &:hover {
          background-color: $primary-color;
          color: white;
          img {
            filter: brightness(0) invert(1);
            &.noFilter {
              filter: none;
            }
          }
        }
      }
      .space {
        width: 20px;
      }
    }
  }
  @include mdportrait {
    background: white;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100% !important;
    z-index: 1;
    .logo-n-title {
      .logo {
        height: 120px;
        width: 120px;
        margin-top: 60px;
      }
      .title {
        margin-top: 10px;
        font-size: 35px;
      }
      .subTitle {
        display: flex;
        width: 70%;
        text-align: center;
      }
    }
    .download {
      h6 {
        display: none;
      }
      .buttons {
        flex-direction: column;
        align-items: center;
        width: 100%;
        .btn-app {
          height: 55px;
          width: 60%;
          margin-top: 15px;
          font-size: 20px;
          font-weight: 600;
        }
        .space {
          width: 20px;
        }
      }
    }
  }
}
