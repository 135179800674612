.desktopAppDetail {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  .head {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    .platform {
      height: 100px;
      display: flex;
      .logo {
        height: 100%;
        width: auto;
        margin-right: 30px;
      }
      .texts {
        line-height: 1;
        color: $primary-color;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .assets {
          font-size: 65px;
          font-weight: 700;
        }
        .builtFor {
          font-size: 30px;
          font-weight: 600;
        }
      }
    }
    .version {
      margin-top: 25px;
      font-size: 20px;
    }
  }
  .imgNButtons {
    height: 0;
    flex: 0 0 42%;
    width: 100%;
    display: flex;
    .appScreen {
      flex: 0 0 65%;
      object-fit: contain;
      height: 100%;
      width: auto;
    }
    .buttons {
      flex: 0 0 18%;
      .btnDownload {
        margin-top: 30px;
        height: 60px;
        background: $primary-color;
        font-size: 24px;
        color: white;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
      }
      .btnStore {
        margin-top: 15px;
        border: 1px solid $primary-color;
        border-radius: 4px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          height: 50%;
          margin-right: 8px;
        }
        .texts {
          line-height: 1;
          .downLoadOn {
            font-size: 13px;
          }
          .store {
            font-size: 17px;
            font-weight: 700;
          }
        }
      }
    }
  }
}
