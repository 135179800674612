@import '_mixin';
@import 'footer-rates';

$trans-time: 300ms;
$width: 500px;

.login-page {
  min-height: 100vh;
  flex: 1;
  .img-logo {
    width: 30%;
  }
  .login-form {
    margin: 50px 0 100px 0;
    width: $width;
    @include sm {
      width: 90%;
    }
    .group {
      position: relative;
      margin: 15px 0;
    }
    input,
    button {
      width: 100%;
      @media only screen and (max-width: #{768px}) {
        width: 100%;
      }
    }
    input:-webkit-autofill {
      box-shadow: 0 0 0px 1000px #fff inset !important;
      background-color: #faffbd !important;
      background-image: none !important;
      color: #000000 !important;
    }
    .btn-forgot {
      background: none;
      border: none;
      border-bottom: 1px solid #182542;
      width: unset;
    }
    .btn-unbank {
      margin-top: auto;
      margin-bottom: auto;
      background-color: $primary-color;
      color: white;
    }
    input[type='password'],
    input[type='text'] {
      background: none;
      color: gray;
      font-size: 18px;
      padding: 10px 10px 10px 5px;
      display: block;
      border: none;
      border-radius: 0;
      &:focus {
        outline: none;
      }
      &:focus ~ label,
      &:valid ~ label {
        top: -14px;
        font-size: 12px;
        color: black;
      }
      &:focus ~ .bar:before {
        width: $width;
      }
    }

    input[type='password'] {
      letter-spacing: 0.3em;
    }

    label {
      color: gray;
      font-size: 16px;
      font-weight: normal;
      position: absolute;
      pointer-events: none;
      left: 5px;
      top: 10px;
      transition: $trans-time ease all;
    }
  }
  .loading-login {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    display: flex;
    > div {
      margin: auto !important;
    }
  }
  .btn-iced-index {
    position: fixed;
    right: 35px;
    top: 25px;
    font-size: 18px;
    border: 1.5px solid $primary-color;
    border-radius: 0;
    padding: 14px 35px;
    &:hover {
      background-color: $primary-color;
      color: white;
    }
    @include mdportrait {
      right: 20px;
      top: 20px;
      font-size: 14px;
      padding: 6px 10px;
    }
  }
}
