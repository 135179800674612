.moneyMarkets {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  .moneyMarketLogo {
    position: absolute;
    width: 25%;
    top: 18%;
  }
  .searchWrapper {
    position: relative;
    width: 55%;
    height: 65px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    display: flex;
    align-items: center;
    padding: 0 40px;
    input {
      padding: 0 10px;
      height: 100%;
      width: 0;
      flex: 1;
      border: none;
      background: none;
    }
    img {
      height: 40%;
      width: auto;
      margin-left: 15px;
    }
    .contractList {
      position: absolute !important;
      top: 102%;
      left: 0;
      right: 0;
      height: 300px !important;
      .contractView {
        padding: 3px;
        .contractItm {
          border: 1px solid $vault-border-color;
          width: 100%;
          height: 62px;
          display: flex;
          align-items: center;
          padding: 0 50px;
          font-size: 16px;
        }
      }
    }
  }
  @include md {
    .moneyMarketLogo {
      width: 65%;
    }
    .searchWrapper {
      width: 85%;
      height: 55px;
      border-radius: 8px;
      padding: 0 10px;
      input {
        font-size: 14px;
      }
      img {
        height: 35%;
        width: auto;
        margin-left: 15px;
      }
    }
  }
}
.bondOverview {
  flex: 1;
  display: flex;
  height: 100%;
  .overView {
    flex: 0 0 60%;
    // padding: 70px 0;
    display: flex;
    flex-direction: column;
    .searchWrapper {
      margin: 0 70px;
      height: 55px;
      border: 1px solid $vault-border-color;
      border-radius: 8px;
      display: flex;
      align-items: center;
      padding: 0 30px;
      margin-bottom: 40px;
      input {
        padding: 0 10px;
        height: 100%;
        width: 0;
        flex: 1;
        border: none;
        background: none;
      }
      img {
        height: 40%;
        width: auto;
        margin-left: 15px;
      }
    }
    .bondTitle {
      margin: 0 70px;
      font-size: 26px;
      font-weight: bold;
    }
    .bondValues {
      padding: 20px 70px;
      border-bottom: 1px solid $vault-border-color;
      .item {
        margin: 40px 0;
        display: flex;
        justify-content: space-between;
        font-size: 18px;
        .value {
          font-weight: 600;
        }
      }
    }
    .earnings {
      margin-top: 30px;
      width: unset !important;
      height: calc(95% - (48px + 0.5vw)) !important;
      // border: 1px solid red;
      flex: 1;
      .vaultsView {
        padding: 0 70px;
        .day {
          margin-top: 1rem;
          font-size: 16px;
          line-height: 1;
          padding: 6px 0;
          font-weight: bold;
        }
        .vaults-itm {
          display: flex;
          margin-left: 20px;
          height: 90px;
          img {
            background: #ffeae9;
            height: 40px;
            width: 40px;
            border-radius: 20px;
            margin: auto 20px;
          }
          .name-n-date {
            height: 100%;
            display: flex;
            line-height: 1;
            flex-direction: column;
            justify-content: center;
            .name {
              font-weight: 600;
              font-size: 18px;
            }
            .date {
              font-size: 11px;
            }
          }
          .credit {
            flex: 12%;
            max-width: 12%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            margin-right: 20px;
            .expand {
              display: none;
            }
            &.true {
              text-decoration: underline;
              .value {
                display: none;
              }
              .expand {
                cursor: pointer;
                display: block;
              }
            }
          }
          &:hover {
            background: #f8f9fa;
          }
        }
      }
    }
  }
  .bondCertificate {
    flex: 0 0 40%;
    border-left: 1px solid $vault-border-color;
    display: flex;
    flex-direction: column;
    .lionBond {
      margin: 30px auto 20px auto;
      width: 34%;
      height: auto;
    }
    .header {
      padding: 10px 50px;
      display: flex;
      color: white;
      background: #137EFB;
      justify-content: space-between;
      align-items: center;
      .label {
        font-size: 15px;
      }
      .value {
        font-weight: 600;
        font-size: 17px;
        .hov {
          display: none;
        }
        .def {
          display: block;
        }
        &:hover {
          .hov {
            display: block;
          }
          .def {
            display: none;
          }
        }
      }
    }
    .bondDetailScroll {
      flex: 1;
      height: unset !important;
      width: unset !important;
      .view {
        // padding: 30px 50px 10px 50px;
        display: flex;
        flex-direction: column;
        // .listItem {
        //   margin: 10px 0;
        //   flex: 1;
        //   border: 1px solid $vault-border-color;
        //   flex: 1;
        //   display: flex;
        //   align-items: center;
        //   justify-content: center;
        // }
        .listItem {
          font-size: 1.2rem;
          font-weight: 700;
          cursor: pointer;
          flex: 1;
          min-height: 42px;
          border: 1px solid $vault-border-color;
          display: flex;
          padding: 10% 50px;
          align-items: center;
          justify-content: space-between;
          // margin-bottom: 20px;
          &.sub {
            flex: 0 0 30%;
          }
          &:hover {
            background: whitesmoke;
          }
        }
        .listDetail {
          position: relative;
          flex: 1;
          display: flex;
          flex-direction: column;
          border: 1px solid $vault-border-color;
          padding: 40px 50px;
          .head {
            line-height: 1;
            font-size: 26px;
            font-weight: 600;
          }
          .date {
            font-size: 12px;
          }
          .item {
            margin-top: 10px;
            margin-bottom: 15px;
            display: flex;
            justify-content: space-between;
            .label {
              font-weight: 600;
              .secondary {
                font-weight: normal;
              }
            }
            .value {
              text-align: right;
              .primary {
                font-weight: 600;
                &.up {
                  color: $primary-green;
                }
                &.down {
                  color: $primary-red;
                }
              }
            }
            &.big {
              font-size: 20px;
            }
          }
          .close {
            cursor: pointer;
            position: absolute;
            top: 30px;
            right: 55px;
            font-size: 30px;
          }
          &.sub {
            margin-top: 15px;
            padding: 20px;
            .subHead {
              font-size: 20px;
              font-weight: 600;
              margin-bottom: 10px;
            }
            p {
              font-size: 13px;
            }
            .nb {
              font-size: 14px;
              font-weight: 600;
            }
            .close {
              top: 15px;
              right: 25px;
              font-size: 26px;
            }
          }
        }
        .actionTitle {
          font-size: 22px;
          font-weight: 600;
          margin: 10px 0 20px 0;
        }
        .actionOption {
          margin: 20px 0 10px 0;
          border: 1px solid $vault-border-color;
          padding: 0 20px;
          position: relative;
          flex: 0 0 90px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          cursor: pointer;
          .mainTitle {
            font-size: 18px;
            font-weight: 600;
          }
          .subDesc {
            font-size: 13px;
          }
          .faCpy {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 20px;
            margin: auto;
            font-size: 22px;
          }
          .copied {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: 0.5;
          }
          &:hover {
            background: whitesmoke;
          }
        }
        .loginValidateText {
          padding: 20px;
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 24px;
          text-align: center;
        }
      }
    }
    .footerButtons {
      border-top: 0.5px solid #f8f9fa;
      font-size: 1.2rem;
      font-weight: 700;
      padding: 0 50px 0px 50px;
      display: flex;
      // flex-wrap: wrap;
      justify-content: space-between;
      .btnShare,
      .btnOwner {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        &:hover {
          // background-color: $primary-color;
          // color: white;
          font-size: 19px;
        }
        &.false {
          opacity: 0.5;
        }
      }

      .btnShare {
        height: 50px;
        font-weight: 700;
        flex: 0 0 33.33%;
        margin: 10px 0;
        // margin-bottom: 15px;
        // border: 1px solid $primary-color;
        color: $primary-color;
      }
      .btnOwner {
        height: 50px;
        flex: 0 0 63%;
        background: $primary-color;
        color: white;
      }
    }
  }
  @include mdportrait {
    flex-direction: column;
    flex: unset;
    padding: 0;
    .overView {
      flex: unset;
      padding: 20px 0;
      .searchWrapper {
        margin: 0 20px;
        padding: 0 20px;
        height: 45px;
        margin-bottom: 20px;
        input {
          font-size: 14px;
        }
      }
      .bondTitle {
        margin: 0 30px;
        font-size: 20px;
      }
      .bondValues {
        padding: 0 30px;
        .item {
          margin: 20px 0;
          font-size: 13px;
          .value {
            text-align: right;
          }
        }
      }
      .earnings {
        margin-top: 30px;
        width: unset !important;
        height: calc(95% - (48px + 0.5vw)) !important;
        // border: 1px solid red;
        flex: 1;
        .vaultsView {
          position: relative !important;
          padding: 0 30px;
          .day {
            margin-top: 0.25rem;
            font-size: 14px;
          }
          .vaults-itm {
            display: flex;
            align-items: center;
            height: 60px;
            margin: 0;
            img {
              height: 25px;
              width: 25px;
              margin: 0 10px;
            }
            .name-n-date {
              margin: 0 !important;
              flex: 1;
              .name {
                font-size: 14px;
              }
              .date {
                font-size: 9px;
              }
            }
            .credit {
              flex: unset !important;
              max-width: unset !important;
              margin: 0;
              .expand {
                display: none;
              }
            }
          }
        }
      }
    }
    .bondCertificate {
      flex: unset;
      border-top: 1px solid $vault-border-color;
      .lionBond {
        margin: 20px auto 15px auto;
        width: 44%;
      }
      .header {
        padding: 6px 20px;
        .label {
          font-size: 13px;
        }
        .value {
          font-size: 14px;
          .hov {
            display: none;
          }
          .def {
            display: block;
          }
          &:hover {
            .hov {
              display: block;
            }
            .def {
              display: none;
            }
          }
        }
      }
      .bondDetailScroll {
        flex: 1;
        .view {
          padding: 20px 20px 10px 20px;
          display: flex;
          flex-direction: column;
          position: relative !important;

          .listDetail {
            padding: 25px 30px;
            .head {
              font-size: 22px;
              width: 85%;
            }
            .date {
              font-size: 11px;
            }
            .item {
              margin-bottom: 13px;
              display: flex;
              font-size: 13px;
              .label {
                font-weight: 600;
              }
              .value {
                text-align: right;
                .primary {
                  font-weight: 600;
                }
              }
              &.big {
                font-size: 20px;
              }
            }
            .close {
              top: 30px;
              right: 25px;
              font-size: 30px;
            }
            &.sub {
              margin-top: 15px;
              padding: 20px;
              .subHead {
                font-size: 20px;
                font-weight: 600;
                margin-bottom: 10px;
              }
              p {
                font-size: 13px;
              }
              .nb {
                font-size: 14px;
                font-weight: 600;
              }
              .close {
                top: 15px;
                right: 25px;
                font-size: 26px;
              }
            }
          }
        }
      }
      .footerButtons {
        // padding: 0 20px 20px 20px;
      }
    }
  }
}
