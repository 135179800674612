
.switcherWrapper{
    height: 10vh; 
    padding: 0px 22px;
    border: 0.5px solid #e7e7e7;
    border-width: 0.5px 0px 0.5px 0px;
    display: flex;
    align-items: center;
}
.iconWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.iconDiv{
    width: 50px;
    height: 50px;
    border: 0.5px solid #e7e7e7;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 10px;
}
.divImg{
    width: 25px;
    height: 25px;
}



.coinListItem{
    display: flex;
    flex-direction: column;
    justify-content: center;
    // justify-content: space-between;
    height: 120px;
    padding: 0px 22px;
    border-bottom: 0.5px solid #e5e5e5;
    font-size: 11px;
    .rowOne, 
    .rowTwo,
    .rowThree{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .title{
        padding-left: 8px;
        font-size: 15px;
        font-weight: 700;
        // padding-bottom:10px;
    }
    .listIcon{
        width: 30px;
        height: 30px;
    }
    .rightSide{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
    .positive{
        font-weight: bold;
        color: green;
    }
    .negative{
        font-weight: bold;
        color: red;
    }
    &:hover{
        background-color: whitesmoke;
        cursor: pointer;
    }
   
}



.buySellPopup{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.bottomPopup{
    height: 30vh;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    width: 100%;
    opacity: 1;
}