.newBreadcrumb {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  p {
    border: 0.5px solid #e5e5e5;
    border-radius: 5px;
    font-size: 0.8rem !important;
    font-weight: 600 !important;
    padding: 5px 15px;
    transition: all ease 0.2s;
    &:hover {
      transform: scale(1.04);
    }
  }
}
