.chatsIoComponent {
  width: 32%;
  flex: 0 0 32%;
  background: white;
  display: flex;
  flex-direction: column;
  border-left: 1px solid $border-color;
  position: relative;
  animation: chatShow 800ms;
  .title {
    margin: 30px 40px 25px 40px;
    line-height: 1;
    font-weight: 700;
    font-size: 45px;
    color: $primary-color;
    position: relative;
  }
  .poweredBy {
    display: flex;
    margin: 0;
    display: flex;
    align-items: baseline;
    font-size: 12px;
    color: white;
    background: $primary-color;
    font-weight: 500;
    padding: 8px 40px;
    img {
      margin-left: 7px;
      filter: brightness(0) invert(1);
      height: 14px;
    }
  }
  .chatsContent {
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    .chatsIoMenu {
      z-index: 4;
      background: white;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      transition: all 400ms ease-in-out;
      height: 0%;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      &.true {
        padding: 30px;
        height: 100%;
      }
      .searchWrapper {
        margin: 10px;
        background: white;
        height: 55px;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
        display: flex;
        align-items: center;
        padding: 0 30px;
        .searchIcn {
          height: 30px;
          width: auto;
        }
        .searchInp {
          flex: 1;
          width: 0;
          border: none;
          background: none;
          outline: none;
          padding: 0 30px;
          font-size: 21px;
        }
      }
      .scrlView {
        height: 0;
        flex: 1;
        > div {
          padding: 10px;
          .menuItm {
            cursor: pointer;
            margin: 30px 0;
            border: 1px solid $border-color;
            box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
            height: 110px;
            display: flex;
            align-items: center;
            padding: 0 40px;
            img {
              height: 40px;
              width: 40px;
              margin-right: 20px;
            }
            span {
              color: $primary-color;
              font-weight: 500;
              font-size: 24px;
            }
          }
        }
      }
    }
    .inboxSidebar {
      flex: 1;
      width: unset;
      animation: unset;
    }
  }
  .fooerMenu {
    z-index: 6;
    height: 80px;
    background: $primary-color;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-top: 1px solid white;

    img {
      cursor: pointer;
      height: 40%;
      width: auto;
      transition: all 400ms ease;
      &:hover {
        transform: scale(1.2);
      }
      &.true {
        filter: drop-shadow(0 0 0.5rem white);
        transform: scale(1.2);
      }
    }
    .center {
      width: 100px;
      height: 80px;
      position: relative;
      img {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 5px;
        height: 100px;
        width: 100px;
        border-radius: 50px;
        background: white;
        padding: 20px;
        padding-right: 28px;
        border: 1px solid $primary-color;
        &.true {
          filter: drop-shadow(0 0 0.5rem $primary-color);
          transform: scale(1.08);
        }
        &:hover {
          filter: drop-shadow(0 0 0.3rem $primary-color);
          transform: scale(1.08);
        }
      }
    }
  }
  @keyframes chatShow {
    from {
      max-width: 0%;
    }
    to {
      max-width: 32%;
    }
  }
}
