@import "../../static/scss/colours";
@import "../../static/scss/mixin";

.shareTokenPage {
  height: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .logo {
      width: 45%;
    }
  }
  .footer {
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f47217;
    color: white;
    font-size: 24px;
    font-weight: 600;
  }
}
