.actionTitle{
    padding-bottom: 20px;
    font-size: 14px;
}

.actionButton{
    display: flex;
    align-items: center;
    margin: 10px 0px;
    border: 0.5px solid #e5e5e5;
    padding: 25px;
    div{
        padding-left: 10px;
        font-weight: 600;
    }
}