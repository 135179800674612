@import 'mixin';
$width: 100%;
$trans-time: 300ms;
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
.login-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 3;
  display: flex;
  .bg-close {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    background: rgba(0, 0, 0, 0.85);
  }
  .login-modal {
    z-index: 1;
    margin: auto;
    font-family: 'Lato', sans-serif;
    width: 95% !important;
    max-width: 500px;
    top: 0;
    display: flex;
    .login-signup {
      display: flex;
      width: 100%;
      .login-wrap {
        flex: 0 0 100% !important;
        max-width: 100% !important;
        .new-cred {
          display: none;
        }
        .head {
          display: flex;
          background-color: $primary-color;
          height: 80px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          img {
            height: 45%;
            filter: brightness(0) invert(1);
          }
          .resetTitle {
            font-size: 30px;
            font-weight: 600;
            color: white;
          }
          .closeBtn {
            cursor: pointer;
            position: absolute;
            width: 30px;
            height: 30px;
            display: flex;
            right: 40px;
            top: 0;
            bottom: 0;
            margin: auto;
            transform: rotate(45deg);
            &::before,
            &::after {
              content: '';
              background: white;
              margin: auto;
              position: absolute;
              left: 0;
              right: 0;
              bottom: 0;
              top: 0;
            }
            &::after {
              height: 100%;
              width: 3px;
            }
            &::before {
              width: 100%;
              height: 3px;
            }
          }
        }
        .login-enter {
          padding: 26px;
          border-radius: 0;
          min-height: 480px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          position: relative;
          .login-text {
            color: $primary-color;
            font-weight: bold;
            font-family: 'Montserrat', sans-serif;
          }
          .gx-account-text {
            font-size: 14px;
            color: $primary-color;
          }
          .login-form {
            .group {
              position: relative;
              margin: 0;
              margin-top: 65px;
              input,
              button {
                width: $width;
                @media only screen and (max-width: #{768px}) {
                  width: 100%;
                }
              }
              input[type='password'],
              input[type='text'] {
                background: none;
                color: #343c5b;
                font-size: 18px;
                padding: 10px 10px 10px 0;
                display: block;
                border: none;
                border-radius: 0;
                border-bottom: 1px solid #505050;
                &:focus {
                  outline: none;
                }
                &:focus ~ label,
                &:valid ~ label {
                  top: -14px;
                  font-size: 12px;
                  color: #343c5b;
                  font-weight: 700;
                }
                &:focus ~ .bar:before {
                  width: $width;
                }
              }

              input[type='password'] {
                letter-spacing: 0.3em;
              }
              label {
                color: #343c5b;
                font-size: 16px;
                font-weight: normal;
                position: absolute;
                pointer-events: none;
                left: 0;
                top: 10px;
                transition: $trans-time ease all;
              }
              .eye {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 15px;
                margin: auto;
                color: $primary-color;
              }
            }
            .forgot {
              margin-top: 20px;
              color: $primary-color;
              cursor: pointer;
            }
            .groupM {
              border: 1px solid $border-color;
              width: 100%;
              height: 70px;
              margin: 2% 0;
              border-radius: 6px;
              overflow: hidden;
              position: relative;
              display: flex;
              .input {
                width: 0;
                flex: 1;
                height: 100%;
                border: none;
                outline: none;
                padding: 0 40px;
              }
              .circle {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 30px;
                margin: auto;
                height: 30%;
              }
              .icon {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 30px;
                margin: auto;
                height: 40%;
              }
              .domain {
                display: flex;
                align-items: center;
                padding: 0 20px;
                font-weight: 600;
                border-left: 1px solid $border-color;
                font-size: 19px;
              }
              .eye {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 15px;
                margin: auto;
                color: $primary-color;
              }
              &.true,
              &.false {
                &::before {
                  content: '';
                  position: absolute;
                  left: 0;
                  top: 0;
                  bottom: 0;
                  width: 15px;
                }
              }
              &.true {
                &::before {
                  background-color: $primary-color;
                }
              }
              &.false {
                &::before {
                  background-color: $primary-red;
                }
              }
            }
            h5 {
              margin-bottom: 20px;
              margin-top: 65px;
            }
            .input-wrapper-2fa {
              width: 100%;
              height: 80px;
              div {
                flex-grow: 1;
                .input-2fa {
                  padding: 10px;
                  width: 90% !important;
                  height: 100%;
                  margin-left: auto !important;
                  margin-right: auto !important;
                  background: white;
                  border-radius: 5px;
                  border: none;
                  outline: none;
                  font-size: 50px;
                  font-weight: 600;
                  color: $primary-color;
                  opacity: 0.8;
                  text-shadow: 0 0 0 $primary-color;
                  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
                }
                input[type='number']::-webkit-inner-spin-button {
                  -webkit-appearance: none;
                }
                input[type='number'] {
                  -moz-appearance: textfield;
                  appearance: textfield;
                  margin: 0;
                }
              }
            }
          }
          .success {
            flex: 1;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .succesTitle {
              width: 90%;
              font-size: 30px;
              font-weight: 600;
              text-align: center;
              margin-bottom: 40px;
            }
            .succesBottom {
              position: absolute;
              bottom: 50px;
            }
          }
          .loading {
            position: absolute;
            z-index: 1;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            background: white;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
      .signup-wrap {
        flex: 0 0 50%;
        max-width: 50%;
        .signup {
          border-radius: 0;
          padding: 26px;
          width: 100%;
          height: 100%;
          background: white;
          .close-btn {
            position: absolute;
            font-size: 30px;
            right: 0;
            top: 0;
          }
          .signup-text {
            font-family: 'Montserrat', sans-serif;
            color: $primary-color;
            font-weight: bold;
          }
          .gx-account-text {
            font-size: 14px;
            color: $primary-color;
          }
          .signup-form {
            .group {
              position: relative;
              margin: 0;
              margin-top: 65px;
              display: flex;
              .domain {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                svg {
                  left: -30px;
                  right: unset;
                }
              }
              svg {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 15px;
                height: 35%;
                margin: auto;
              }
              input,
              button {
                width: $width;
                @media only screen and (max-width: #{768px}) {
                  width: 100%;
                }
              }
              input[type='password'],
              input[type='text'] {
                background: none;
                color: #343c5b;
                font-size: 18px;
                padding: 10px 10px 10px 0;
                display: block;
                border: none;
                border-radius: 0;
                border-bottom: 1px solid #505050;
                &:focus {
                  outline: none;
                }
                &:focus ~ label,
                &:valid ~ label {
                  top: -14px;
                  font-size: 12px;
                  color: #343c5b;
                  font-weight: 700;
                }
                &:focus ~ .bar:before {
                  width: $width;
                }
              }

              input[type='password'] {
                letter-spacing: 0.3em;
              }
              label {
                color: #343c5b;
                font-size: 16px;
                font-weight: normal;
                position: absolute;
                pointer-events: none;
                left: 0;
                top: 10px;
                transition: $trans-time ease all;
              }
              .whatIs {
                position: absolute;
                top: 120%;
                font-size: 14px;
              }
            }
            .otp-group {
              display: flex;
              flex-direction: column;
              flex-grow: 1;
              justify-content: space-around;
              position: relative;
              margin: 0 auto;
              .text {
                color: $primary-color;
                opacity: 0.4;
                font-size: 16px;
              }
              .otp-input-wrapper {
                margin: 0 auto;
                display: flex;
                div {
                  flex-grow: 1;
                  .otp-input {
                    width: 90% !important;
                    height: 50px;
                    margin-left: auto !important;
                    margin-right: auto !important;
                    background: white;
                    border-radius: 5px;
                    border: none;
                    outline: none;
                    font-size: 45px;
                    color: $primary-color;
                    opacity: 0.2;
                    text-shadow: 0 0 0 $primary-color;
                    font-family: 'Montserrat', sans-serif;
                    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
                  }
                  input[type='number']::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                  }
                  input[type='number'] {
                    -moz-appearance: textfield;
                    appearance: textfield;
                    margin: 0;
                  }
                }
              }

              .otp-input-wrapper-onhold {
                width: 100%;
                justify-content: space-between;
                > div {
                  width: 20%;
                  height: 80px;
                  .otp-input {
                    width: 100% !important;
                    height: 100%;
                    border: none;
                   background: #FFFFFF;
box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
border-radius: 5px;
                    font-size: 28px;
                  }
                }
              }
            }
            .conditions {
              font-family: 'Montserrat', sans-serif;
              border: 1px solid #e9e9e9;
              border-radius: 8px;
              margin-top: 12px;
              padding: 12px;
              h3 {
                font-size: 20px;
                color: #343c5b;
                opacity: 0.41;
              }
              .item {
                padding: 6px 0;
                font-size: 12px;
                display: flex;
                justify-content: space-between;
                svg {
                  height: 12px;
                  margin: auto 0;
                }
              }
            }
            .lottie {
              display: flex;
              flex-direction: column;
              flex-grow: 1;
              margin: 0 auto;
              justify-content: space-around;
              h6 {
                font-size: 24px;
                font-weight: 600;
                text-align: center;
                color: $primary-color;
              }
            }
          }
        }
      }
    }
    .twofa-login {
      height: 100%;
      padding: 70px 50px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      .loading {
        display: flex;
        flex-direction: column;
        margin: auto 100px;
        @include md {
          margin: auto auto;
        }
        h6 {
          width: 100%;
          text-align: center;
          color: $primary-color;
        }
      }
      img {
        display: none;
      }
      h5 {
        color: $primary-color;
        font-size: 24px;
        text-align: center;
        margin-bottom: 3rem;
      }
      .input-wrapper-2fa {
        width: 100%;
        height: 80px;
        div {
          flex-grow: 1;
          .input-2fa {
            padding: 10px;
            width: 90% !important;
            height: 100%;
            margin-left: auto !important;
            margin-right: auto !important;
            background: white;
            border-radius: 5px;
            border: none;
            outline: none;
            font-size: 50px;
            font-weight: 600;
            color: $primary-color;
            opacity: 0.8;
            text-shadow: 0 0 0 $primary-color;
            box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
          }
          input[type='number']::-webkit-inner-spin-button {
            -webkit-appearance: none;
          }
          input[type='number'] {
            -moz-appearance: textfield;
            appearance: textfield;
            margin: 0;
          }
        }
      }
      a {
        width: 100%;
        text-align: center;
        margin-top: 2rem;
        font-size: 22px;
        color: $primary-color;
        opacity: 0.8;
      }
      @include md {
        img {
          display: block;
          margin-bottom: 2rem;
        }
        h5 {
          font-size: 20px;
        }
        .input-wrapper-2fa {
          div {
            .input-2fa {
              padding: 4px;
              font-size: 40px;
              height: 55px;
            }
          }
        }
      }
    }

    .btn-darkblue {
      color: #fff;
      background: $primary-color;
      border-color: $primary-color;
      font-size: 1.3rem;
      &:hover {
        color: #fff;
        background-color: #38393a;
        border-color: #38393a;
      }
      &:disabled {
        opacity: 0.7;
      }
    }
  }
}
