.noChartView {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  z-index: 2;
  background: white;
  .underConstruction {
    display: flex;
    padding: 0 70px;
    align-items: center;
    flex: 0 0 70%;
    p {
      width: 60%;
      margin: 0;
      font-size: 25px;
    }
  }
  .noWorries {
    flex: 1;
    margin-top: 2px;
    border-top: 1px solid #e7e7e7;
    display: flex;
    padding: 0 70px;
    align-items: center;
    position: relative;
    p {
      width: 45%;
      margin: 0;
      font-size: 22px;
    }
    .tripleArrow {
      position: absolute;
      height: 50px;
      top: 0;
      bottom: 0;
      margin: auto;
      right: 70px;
    }
  }
}
