.malls {
  width: 100%;
  overflow-y: hidden;
  position: absolute;
  inset: 0;

  .malls-navbar {
    width: 100%;
    height: 8%;
    border-bottom: 0.5px solid #e7e7e7;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 4rem;

    .malls-icon {
      width: 10%;
      height: 100%;
      // border: 1px solid red;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .search-mall {
      width: 50%;
      height: 100%;
      border: 0.5px solid #ededed;
      background: rgba(237, 237, 237, 0.37);
      border-radius: 10px;
      padding: 0.5rem 2rem;
      display: flex;
      align-items: center;
      .all {
        width: 32%;
        height: 100%;
        border-right: 3px solid #e7e7e7;
        display: flex;
        align-items: center;
        font-size: 1rem;
        color: #343c5b;
        font-weight: 700;
        opacity: 0.25;
        gap: 1rem;
        p {
          margin-bottom: 0;
        }
        .arroww {
          display: flex;
          align-items: center;
          img {
            width: 15px;
            height: 15px;
            cursor: pointer;
          }
        }
      }
      .im {
        width: 100%;
        height: 100%;
        // padding-left: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1rem;
        color: #343c5b;
        font-weight: 700;
        // opacity: 0.25;
        gap: 1rem;
        input {
          border: none;
          font-size: 1rem;
          color: #343c5b;
          font-weight: 700;
          background: transparent;
        }
      }
    }

    .right-top {
      // width: 32%;
      height: 100%;
      display: flex;
      .divv {
        // width: 50%;
        height: 100%;
        display: flex;
        gap: 1rem;
        align-items: center;
        p {
          margin-bottom: 0;
          font-size: 0.9rem;
          color: #343c5b;
          font-weight: 600;
        }
      }
    }
  }

  .w-space {
    width: 100%;
    height: 4%;
    padding: 0 4rem;
    .optionss {
      width: 30%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .each-option {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          margin-bottom: 0;
          font-size: 0.9rem;
        }
        div {
          display: flex;
          gap: 5px;
          transition: all ease 0.3s;
          cursor: pointer;
          &:hover {
            scale: 1.1;
          }
        }
      }
      .hamburger {
        color: #343c5b;
        font-weight: 700;
        display: flex;
        p {
          margin-bottom: 0;
        }
        .hov-animate {
          transition: all ease 0.3s;
          &:hover {
            scale: 1.08;
          }
        }
      }
    }
  }

  .malls-content {
    width: 100%;
    height: 100%;
    padding: 2% 4% 0;
    // overflow-y: scroll;
    background: rgba(237, 237, 237, 0.37);

    .image-container {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .course-img {
        width: 60%;
        height: 30rem;
        background-image: url('../../static/images/malls/imgg1.svg');
        background-position: 0%;
        background-repeat: no-repeat;
        background-size: cover;
        cursor: pointer;
        transition: all ease 0.4s;

        &:hover {
          scale: 1.02;
        }
      }

      .imgs {
        width: 38%;
        height: 30rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .imgg1 {
          width: 100%;
          height: 48.5%;
          background-image: url('../../static/images/malls/imgg2.svg');
          background-position: 0%;
          background-repeat: no-repeat;
          background-size: cover;
          cursor: pointer;
          transition: all ease 0.4s;

          &:hover {
            scale: 1.02;
          }
        }

        .imgg2 {
          width: 100%;
          height: 48.5%;
          background-image: url('../../static/images/malls/imgg3.svg');
          background-position: 0%;
          background-repeat: no-repeat;
          background-size: cover;
          cursor: pointer;
          transition: all ease 0.4s;

          &:hover {
            scale: 1.02;
          }
        }
      }
    }

    .best-sellers {
      width: 100%;
      padding: 3rem 0;

      .headingg {
        font-size: 1.5rem;
        font-weight: 600;
        color: #343c5b;
        margin-bottom: 0;
      }

      .bs-products {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        column-gap: 2.75%;

        .each-productt {
          width: 31.5%;
          background: white;
          border-radius: 25px;
          padding: 2rem;

          cursor: pointer;
          transition: all ease 0.4s;
          margin-top: 2rem;

          .top-sec {
            width: 100%;
            // height: 10rem;
            display: flex;
            align-items: center;
            justify-content: space-between;

            & > :nth-child(1) {
              border: 0.5px solid #e7e7e7;
              border-radius: 10px;
              padding: 1rem;
              aspect-ratio: 1/1;
            }

            img {
              width: 100%;
              height: 100%;
            }

            button {
              padding: 1rem 1.5rem;
              border: none;
              cursor: pointer;
              color: #343c5b;
              background: #e5e5e5;
              font-weight: 700;
              border-radius: 10px;
            }
          }

          .mid-sec {
            width: 100%;
            display: flex;
            color: #343c5b;
            font-size: 1.5rem;
            font-weight: 700;
            margin-top: 2rem;
          }

          .bottom-sec {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 3rem;

            .amnt {
              color: #343c5b;
              font-size: 1.5rem;
              font-weight: 600;
            }

            .first {
              color: #343c5b;
              opacity: 0.5;
              font-weight: 700;
            }
          }

          &:hover {
            box-shadow: 0 12px 28px 0 rgb(0 0 0 / 20%),
              0 2px 4px 0 rgb(0 0 0 / 10%),
              inset 0 0 0 1px hsl(0deg 0% 100% / 5%);
          }
        }
      }
    }
    .subs-content {
      width: 100%;
      padding: 2rem 2rem 3rem 2rem;
      background: #ffffff;
      display: flex;
      flex-direction: column;
      gap: 2.5rem;
      margin-top: 2rem;
      .contt {
        width: 100%;
        display: flex;
        .div01 {
          width: 40%;
          p {
            margin-bottom: 0;
          }
          .pro-dett {
            display: flex;
            margin-top: 0.75rem;
            gap: 5px;
            align-items: center;
            img {
              width: 10%;
            }
          }
        }
        .div02 {
          width: 16%;
          p {
            margin-bottom: 0;
          }
          .pro-dett {
            display: flex;
            margin-top: 0.2rem;
            img {
              width: 10%;
            }
          }
        }
      }
    }
  }
}

.malls-products {
  width: 100%;
  height: 100%;

  .content-area {
    width: 100%;
    height: 96%;
    padding: 3rem 6rem 0rem 3rem;
    display: flex;
    justify-content: space-between;
    background: #e5e5e5;
    .left-divv {
      width: 63%;
      height: 100%;
      background: #ffffff;
      .productt-det {
        width: 100%;
        display: flex;
        gap: 2rem;
        padding: 2rem 3rem;
        align-items: center;
        .pro-name {
          p {
            margin-bottom: 0;
          }
        }
      }
      .about-section {
        width: 88%;
        padding: 2rem 0 3rem;
        border-bottom: 0.5px solid #e5e5e5;
        margin: auto;
      }
      .price-section {
        width: 100%;
        height: 78%;
        overflow-y: scroll;
        padding: 2rem 0;
        .price-divv {
          width: 88%;
          margin: auto;
          padding-top: 2rem;
          padding-bottom: 2rem;
          border-bottom: 0.5px solid #e5e5e5;
          .pricing {
            display: flex;
            width: 100%;
            // border: 1px solid;
            padding: 1rem 0;
            align-items: center;
            .billing-txt {
              width: 58%;
            }
            .pricing-btns {
              width: 42%;
              display: flex;
              justify-content: space-between;
              .onetym-btn {
                width: 48.5%;
                border: none;
                border-radius: 10px;
                padding: 1rem 0;
                color: #343c5b;
                font-weight: 700;
              }
            }
          }
        }
      }
    }
    .right-divv {
      width: 35%;
      height: 97%;
      background: #ffffff;
      position: relative;
      .amount-details {
        width: 100%;
        display: flex;
        padding: 2.5rem 2rem;
        border-bottom: 1px solid #e5e5e5;
        .left-amnt {
          width: 50%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          p {
            margin-bottom: 0;
          }
          .amnt-font {
            font-weight: 700;
            color: #343c5b;
            font-size: 2rem;
          }
          .text-font {
            font-weight: 700;
            color: #343c5b;
            font-size: 1rem;
            opacity: 0.5;
          }
        }
        .left-amnt1 {
          width: 50%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-end;
          p {
            margin-bottom: 0;
          }
          .amnt-font {
            font-weight: 700;
            color: #343c5b;
            font-size: 2rem;
          }
          .text-font {
            font-weight: 700;
            color: #343c5b;
            font-size: 1rem;
            opacity: 0.5;
          }
        }
      }
      .billing {
        width: 100%;
        padding: 2rem;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #e5e5e5;
        div {
          font-weight: 700;
          color: #343c5b;
          font-size: 1rem;
          opacity: 0.5;
        }
      }
      .buttonss {
        width: 100%;
        display: flex;
        position: absolute;
        bottom: 5%;
        left: 0;
        justify-content: space-between;
        padding: 0 2rem;
        .buy-btn {
          color: #ffffff;
          font-weight: 700;
          font-size: 1.25rem;
          background: #78d0b1;
          border: none;
          border-radius: 10px;
          width: 45%;
          padding: 1rem 2rem;
          transition: all ease 0.3s;
          &:hover {
            scale: 1.1;
          }
        }
        .share-btn {
          color: #343c5b;
          font-weight: 700;
          font-size: 1.25rem;
          background: #e5e5e5;
          border: none;
          border-radius: 10px;
          width: 45%;
          padding: 1rem 2rem;
          transition: all ease 0.3s;
          &:hover {
            scale: 1.1;
          }
        }
      }
      .buy-step1 {
        width: 100%;
        height: 100%;
        padding: 2rem;
        .searchh {
          width: 100%;
          height: 5rem;
          margin-top: 3rem;
          border: 0.5px solid #e5e5e5;
          border-radius: 15px;
          input {
            border: none;
            font-size: 1rem;
            color: #343c5b;
            font-weight: 700;
            width: 100%;
            padding: 1rem;
            height: 100%;
            opacity: 0.25;
          }
        }
        .coin-options {
          width: 100%;
          height: calc(100% - 8.5rem);
          overflow-y: scroll;
          padding: 1rem 0;
          .coin-comp {
            margin-top: 1rem;
            width: 100%;
            height: 5rem;
            border: 0.5px solid #e5e5e5;
            border-radius: 15px;
            padding: 1rem;
            display: flex;
            align-items: center;
            cursor: pointer;
            .coinnamee {
              width: 50%;
              padding-left: 10px;
              font-size: 1rem;
              color: #343c5b;
              font-weight: 700;
            }
            .coinvaluee {
              width: 44%;
              display: flex;
              justify-content: flex-end;
              font-size: 1rem;
              color: #343c5b;
              font-weight: 700;
            }
          }
        }
        .boxx {
          width: 100%;
          height: 5rem;
          margin-top: 3rem;
          border: 0.5px solid #e5e5e5;
          border-radius: 15px;
          font-size: 0.95rem;
          font-weight: 650;
          padding-left: 1.5rem;
          color: #343c5b;
          display: flex;
          align-items: center;
          transition: all ease 0.3s;
          cursor: pointer;
          &:hover {
            scale: 1.08;
          }
        }
        .loader-anim {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          .bottom-textt {
            position: absolute;
            transform: translate(-50%, -50%);
            left: 47%;
            bottom: 0%;
            color: #343c5b;
            font-size: 1.25rem;
            font-weight: 700;
          }
        }
        .success-text {
          color: #343c5b;
          font-size: 1.25rem;
          font-weight: 650;
          text-align: center;
          margin-top: 2rem;
        }
      }
    }
  }
}
