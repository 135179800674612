.addcrypto {
  width: 100%;
  height: 100%;

  .addcrypto-breadcrumb {
    display: flex;
    padding: 5px 0px 5px 1.85rem;
    height: 4%;
    border-top: 0.5px solid rgb(231, 231, 231);
    border-bottom: 0.5px solid rgb(231, 231, 231);
    align-items: center;
    width: 100%;
    border-radius: 0px;
    min-height: 38px;
    font-size: 1.1rem;
    font-weight: 600;
  }

  .addcrypto-heading {
    display: flex;
    font-size: 0.85rem;
    font-weight: 400;
    height: 5%;
    padding: 0 1.85rem;
    background: rgba(229, 229, 229, 0.2509803922);
    border-bottom: 0.5px solid #e7e7e7;
    align-items: center;
  }

  .step1-container {
    width: 100%;
    height: calc(100% - 9%);
    position: relative;

    .network-container {
      width: 100%;
      height: calc(100% - 5rem);
      overflow-y: scroll;

      .each-network {
        width: 100%;
        height: 8.7rem;
        border-bottom: 0.5px solid #e7e7e7;
        display: flex;
        padding: 0 1.85rem;
        align-items: center;

        p {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          font-size: 1.2rem;
          font-weight: 500;
          cursor: pointer;
          margin: 0;
          transition: all ease 0.2s;

          &:hover {
            transform: scale(1.05);
          }
        }
      }
    }

    .step1-footer {
      position: absolute;
      width: 100%;
      display: flex;
      bottom: 1rem;
      left: 0;
      height: 3.5rem;
      padding: 0 1.5rem;

      div {
        background: #ff5e5e;
        color: white;
        font-weight: 600;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all ease 0.2s;
        width: 100%;
        border-radius: 35px;

        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }

  .step2-container {
    width: 100%;
    height: calc(100% - 9%);
    position: relative;
    overflow-y: scroll;

    .qr-div {
      width: 100%;
      height: 20rem;
      padding: 0 1.85rem;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 200px;
        height: 200px;
      }
    }

    .instruction-div {
      width: 100%;
      padding: 0 1.85rem;
      margin-bottom: 3rem;

      div {
        width: 100%;
        border-radius: 15px;
        padding: 1.5rem;
        background: rgba(241, 244, 246, 0.5);
      }
    }

    .network-div {
      width: 100%;
      padding: 0 1.85rem;
      margin-bottom: 3rem;

      .headerTxt {
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: 1rem;
      }

      .network-field {
        width: 100%;
        border: 0.5px solid #ebebeb;
        border-radius: 35px;
        padding: 1.5rem;
      }
    }

    .addresss-div {
      width: 100%;
      padding: 0 1.85rem;
      margin-bottom: 3rem;

      .addresss-headerTxt {
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .explorerText {
          font-weight: 100;
          cursor: pointer;
          text-decoration: underline;
        }
      }

      .address-field {
        width: 100%;
        border: 0.5px solid #ebebeb;
        border-radius: 15px;
        padding: 1.5rem;
        overflow-wrap: break-word;
      }

      .address-field1 {
        width: 100%;
        border: 0.5px solid #ebebeb;
        border-radius: 15px;
        padding: 1.5rem;

        div {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          transition: all ease 0.2s;

          &:hover {
            transform: scale(1.05);
          }
        }
      }
    }

    .hash-div {
      width: 100%;
      padding: 0 1.85rem;
      margin-bottom: 3rem;

      .hash-headerTxt {
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: 1rem;
      }

      .hash-field {
        width: 100%;
        border: 0.5px solid #ebebeb;
        border-radius: 35px;
        padding: 1.5rem;
        display: flex;

        input {
          width: 85%;
          height: 100%;
          border: none;
          border-radius: 35px;
        }

        .pasteIcon {
          width: 15%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          img {
            cursor: pointer;
            transition: all ease 0.2s;

            &:hover {
              transform: scale(1.05);
            }
          }
        }
      }
    }

    .deposit-div {
      width: calc(100% - 3.7rem);
      display: flex;
      flex-direction: column;
      border-radius: 15px;
      padding: 1.5rem;
      background: rgba(241, 244, 246, 0.5);
      gap: 1.5rem;
      margin: 0 auto 5rem auto;

      .topp-div {
        width: 100%;
        display: flex;
        justify-content: space-between;
        font-size: 1rem;
      }
    }

    .submit-Btn {
      width: 100%;
      height: 3.5rem;
      padding: 0 1.85rem;
      margin-bottom: 2rem;

      div {
        width: 100%;
        height: 100%;
        border-radius: 35px;
        background: #78d0b1;
        color: #fff;
        cursor: pointer;
        transition: all ease 0.2s;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          transform: scale(1.05);
        }
      }
    }

    .bottom-btnsDiv {
      width: 100%;
      height: 10rem;
      padding: 1.5rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-top: 0.5px solid #e5e5e5;

      .topp-btn {
        display: flex;
        width: 100%;
        justify-content: space-between;

        div {
          font-size: 1.1rem;
          padding: 0.75rem 2.2rem;
          width: 48%;
          font-weight: 600;
          border-radius: 35px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          transition: all ease 0.2s;

          &:hover {
            transform: scale(1.05);
          }
        }
      }

      .bottomm-btn {
        display: flex;
        width: 100%;
        padding: 0.75rem 2.2rem;
        color: #fff;
        justify-content: center;
        align-items: center;
        border-radius: 35px;
        font-weight: 600;
        cursor: pointer;
        transition: all ease 0.2s;

        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }
}
