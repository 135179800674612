.paginationComponent {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid #ebebeb;
  padding: 0.7rem 60px;
  z-index: 2;
  font-size: 1rem;
  font-weight: 650;
  color: #343c5b;
  display: flex;
  justify-content: space-between;
  background: white;

  & > div {
    display: flex;
    gap: 0.75rem;

    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.15rem 0.6rem;
      cursor: pointer;
    }
  }

  & > .perPage {
  }
}
