.home-page {
  background-color: $card-color;
  display: flex;
  flex-direction: column;
  .expand {
    cursor: pointer;
    text-decoration: underline;
    font-weight: 600;
  }
  .content-home-page {
    flex-grow: 1;
    display: flex;
    height: 0;
    .one {
      flex: 0 0 70%;
      padding: 40px;
      padding-right: 20px;
      display: flex;
      overflow: hidden;
      .contentCard {
        flex: 1;
      }
    }
    .two {
      flex: 0 0 30%;
      max-width: 30%;
      display: flex;
      flex-direction: column;
      padding: 20px;
      padding-left: 0;
      .orderBookWrap {
        flex: 0 0 55%;
        padding: 20px;
        .order-book-card {
          .opt-tab {
            height: 60px;
            display: flex;
            align-items: center;
            .tab-itm {
              font-weight: bold;
              padding-top: 10px;
              padding-bottom: 10px;
              &:last-child {
                padding-left: 0;
              }
              &:hover {
                transform: none;
              }
            }
          }
          .order-book-list {
            h4 {
              height: 55px;
              display: flex;
              align-items: center;
              font-size: 1.3rem;
              color: $primary-color;
              border: 1px $border-color;
              border-style: solid none;
            }
          }
        }
      }
      .tradeCardWrap {
        flex: 0 0 45%;
        padding: 20px;
        padding-top: 0;
        .trade-card {
          position: relative;
          overflow: hidden;
          height: 100%;
          display: flex;
          flex-direction: column;
          .lottieLoading {
            position: absolute;
            background: white;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
          }
          .mainTab {
            height: 55px;
            display: flex;
            .tabItm {
              flex: 0 0 50%;
              background: #f2f2f2;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 19px;
              font-weight: 600;
              color: #464b4e;
              svg {
                margin-left: 6px;
                font-size: 17px;
              }
              &.true {
                background: #464b4e;
                color: #f2f2f2;
              }
            }
          }
          .subTab {
            height: 55px;
            display: flex;
            .tabSubItm {
              flex: 0 0 25%;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 17px;
              border-bottom: 1px solid $border-color;
              position: relative;
              opacity: 0.5;
              &.true {
                opacity: 1;
                font-weight: bold;
                &::before {
                  content: '';
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  right: 0;
                  height: 2px;
                  background-color: black;
                }
              }
            }
          }
          .contents {
            flex: 1;
            display: flex;
            flex-direction: column;
            padding: 40px 60px;
            justify-content: space-between;
            .box {
              height: 60px;
              border: 1px solid $border-color;
              display: flex;
              .label {
                position: relative;
                height: 100%;
                margin-right: auto;
                display: flex;
                align-items: center;
                padding-left: 15px;
                svg {
                  margin-left: 6px;
                }
                .labelDropDown {
                  font-size: 13px;
                  position: absolute;
                  top: 80%;
                  left: -1px;
                  height: 36px;
                  background: white;
                  display: none;
                  padding: 0 15px;
                  align-items: center;
                  border: 1px solid $border-color;
                }
                &:hover {
                  .labelDropDown {
                    cursor: pointer;
                    display: flex;
                  }
                }
              }
              .value {
                height: 100%;
                display: flex;
                align-items: center;
                padding-right: 15px;
                font-weight: 600;
              }
              input {
                width: 0;
                flex: 1;
                text-align: right;
                border: none;
                background: none;
              }
              .btnBox {
                border-left: 1px solid $border-color;
                height: 100%;
                width: 80px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
            .rangeWrap {
              position: relative;
              height: 36px;
              border-radius: 18px;
              border: 1px solid $border-color;
              overflow: hidden;
              display: flex;
              font-size: 13px;
              white-space: nowrap;
              .rangeIn {
                padding: 0 4px;
                flex: 0 0 0%;
                background: #464b4e;
                color: white;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 600;
              }
              .rangeOut {
                padding: 0 4px;
                flex: 1;
                background: white;
                color: #343C5B;
                display: flex;
                align-items: center;
                justify-content: center;
              }
              .rangeSlider {
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                height: 100%;
                width: 100%;
              }
            }
          }
          .button {
            height: 55px;
            background: #70c582;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            font-weight: 600;
          }
          .tradeSumHead {
            height: 75px;
            display: flex;
            border-bottom: 1px solid $border-color;
            .ret {
              cursor: pointer;
              flex: 0 0 75px;
              background: $border-color;
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                width: 40%;
                height: 40%;
                transition: all ease 0.4s;
              }
              &:hover {
                filter: contrast(1.05);
                img {
                  transform: scale(1.05);
                }
              }
            }
            .txtMain {
              flex: 1;
              font-size: 29px;
              font-weight: 600;
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .btnConfirm {
              cursor: pointer;
              width: fit-content;
              background: $border-color;
              font-weight: 500;
              display: flex;
              align-items: center;
              font-size: 20px;
              padding: 0 20px;
              img {
                height: 23px;
                width: 23px;
                object-fit: contain;
                margin-right: 10px;
              }
              &:hover {
                filter: contrast(1.05);
                * {
                  transform: scale(1.05);
                }
              }
            }
          }
          .tradeContent {
            height: 0 !important;
            flex: 1;
            > div {
              padding: 40px 60px;
            }
            .boxLabel {
              display: flex;
              justify-content: space-between;
              margin: 8px 0;
              span {
                cursor: pointer;
                font-weight: 500;
                text-decoration: underline;
                &:hover {
                  font-weight: 600;
                }
              }
            }
            .box {
              height: 65px;
              margin-bottom: 20px;
              .btnBox {
                min-width: 100px;
                padding: 0 10px;
                width: fit-content;
                img {
                  height: 40%;
                  margin: 0 8px;
                }
                &.true {
                  cursor: pointer;
                  background: $border-color;
                  &:hover {
                    filter: contrast(1.05);
                    * {
                      transform: scale(1.05);
                    }
                  }
                }
              }
            }
            .valueBox {
              height: 120px;
              border: 1px solid $border-color;
              margin-bottom: 15px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              padding: 0 8%;
              .mTxt {
                display: flex;
                justify-content: space-between;
                font-size: 22px;
                font-weight: 600;
              }
              .sTxt {
                display: flex;
                justify-content: space-between;
                font-size: 17px;
              }
            }
          }
          .totalFees {
            flex: 0 0 65px;
            border-top: 1px solid $border-color;
            display: flex;
            font-size: 20px;
            font-weight: 600;
            .total {
              flex: 1;
              display: flex;
              align-items: center;
              padding: 0 25px;
            }
            .totalNative,
            .totalUsd {
              display: flex;
              align-items: center;
              padding: 0 25px;
              border-left: 1px solid $border-color;
            }
          }
          .searchBox {
            height: 65px;
            flex: 0 0 65px;
            min-height: 65px;
            border: none;
            background: none;
            padding: 0 30px;
            border-bottom: 1px solid $border-color;
          }
          .tabsCrFi {
            height: 65px;
            display: flex;
            border-bottom: 1px solid $border-color;
            .tabItm {
              flex: 0 0 50%;
              cursor: pointer;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 20px;
              font-weight: 500;
              transition: all 400ms ease;
              position: relative;
              &::before {
                position: absolute;
                width: 0%;
                margin: auto;
                content: '';
                left: 0;
                right: 0;
                bottom: 0;
                height: 3px;
                background: $primary-color;
                transition: all 400ms ease;
              }
              &.true {
                font-weight: 700;
                &::before {
                  width: 100%;
                }
              }
            }
          }
          .coinListWrapper {
            > div {
              display: flex;
              flex-direction: column;
              .coinItem {
                flex: 0 0 33.3%;
                border-bottom: 1px solid $border-color;
                display: flex;
                align-items: center;
                padding: 0 10%;
                font-size: 24px;
                font-weight: 500;
                .coin,
                .selectedTik {
                  height: 26px;
                  width: 26px;
                }
                .coin {
                  margin-right: 8px;
                }
                .selectedTik {
                  margin-left: auto;
                }
              }
            }
          }
        }
      }
    }
  }
  button {
    outline: none !important;
  }
  .card-dark {
    background-color: $card-color !important;
    border: 1px solid $border-color;
    min-height: 260px;
    .opt-tab {
      color: $secondary-color;
      text-align: center;
      border-bottom: 1px solid $border-color;
      .tab-itm {
        position: relative;
        cursor: pointer;
        &:hover {
          font-weight: bold;
          color: $primary-color;
          transform: scaleX(1.1);
        }
        &.active {
          font-weight: bold;
          color: $primary-color;
          &::after {
            content: '';
            bottom: -1px;
            left: calc(50% - 8px);
            position: absolute;
            display: block;
            width: 0;
            height: 0;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 8px solid $card-color;
          }
          &::before {
            content: '';
            bottom: -1px;
            left: calc(50% - 10px);
            position: absolute;
            display: block;
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid $border-color;
          }
        }
      }
    }
    .more {
      cursor: pointer;
      border-top: 1px solid #474654;
      color: $secondary-color;
      font-size: 16px;
      margin: 0;
      &:hover {
        color: $primary-color;
        font-weight: bold;
        background-color: $secondary-color;
      }
    }
    .tab-view-scroll {
      height: 0;
      overflow-y: auto;
    }
  }

  .card-pulse-market {
    .market-tab-view {
      .market-itm {
        border-bottom: 1px solid $border-color;
        .news-icon {
          height: 100px !important;
          width: 130px !important;
          object-fit: cover;
        }
        h3 {
          color: $primary-color;
          font-weight: bold;
          font-size: 14px;
        }
        p {
          font-weight: normal;
          font-size: 12px;
          line-height: 15px;
          color: $primary-color;
          a {
            color: $primary-color;
            font-weight: bold;
          }
        }
        h6 {
          font-weight: normal;
          font-size: 13px;
          line-height: 17px;
          color: $secondary-color;
        }
      }
      h5 {
        border-top: 1px solid $border-color;
        color: $secondary-color;
        font-size: 16px;
        margin: 0;
      }
    }
  }
  .card-funds {
    .myfunds-tab-view {
      .tab-btn {
        padding: 8px 0;
        .tab-btn-itm {
          color: $secondary-color;
          padding: 4px 8px 4px 8px;
          border-radius: 4px;
          cursor: pointer;
          &:hover {
            background: $secondary-color;
            color: $primary-color;
          }
          &.active {
            background: $border-color;
            color: $primary-color;
          }
        }
      }
      .fund-list {
        .fund-list-item {
          height: 60px;
          display: flex;
          h5 {
            color: $secondary-color;
            margin-top: auto;
            margin-bottom: auto;
            line-height: 1;
            &.link {
              cursor: pointer;
            }
          }
          img {
            width: 30px;
            height: 30px;
            border-radius: 15px;
          }
        }
        .fund-list-item:nth-child(odd) {
          background: $border-color;
        }
      }
    }
  }
  .live-trade-card {
    .tabview-live-trades {
      .trade-list-head {
        background: $border-color;
        font-size: 12px;
        color: $primary-color;
      }
      .trade-list {
        .live-trades-item {
          min-height: 155px;
          background: whitesmoke;
          h2 {
            color: $primary-color;
            font-weight: bolder;
            text-align: center;
          }
          .fund-list-item {
            padding-top: 6px;
            padding-bottom: 6px;
            font-size: 13px;
            text-align: center;
            h6 {
              font-size: 13px;
              line-height: 23px;
              color: $primary-color;
            }
            p {
              font-size: 10px;
              padding: 0;
            }
          }
          .lds-default {
            display: inline-block;
            position: relative;
            width: 80px;
            height: 80px;

            div {
              position: absolute;
              width: 6px;
              height: 6px;
              background: #fff;
              border-radius: 50%;
              animation: lds-default 1.2s linear infinite;

              &:nth-child(1) {
                animation-delay: 0s;
                top: 37px;
                left: 66px;
              }

              &:nth-child(2) {
                animation-delay: -0.1s;
                top: 22px;
                left: 62px;
              }

              &:nth-child(3) {
                animation-delay: -0.2s;
                top: 11px;
                left: 52px;
              }

              &:nth-child(4) {
                animation-delay: -0.3s;
                top: 7px;
                left: 37px;
              }

              &:nth-child(5) {
                animation-delay: -0.4s;
                top: 11px;
                left: 22px;
              }

              &:nth-child(6) {
                animation-delay: -0.5s;
                top: 22px;
                left: 11px;
              }

              &:nth-child(7) {
                animation-delay: -0.6s;
                top: 37px;
                left: 7px;
              }

              &:nth-child(8) {
                animation-delay: -0.7s;
                top: 52px;
                left: 11px;
              }

              &:nth-child(9) {
                animation-delay: -0.8s;
                top: 62px;
                left: 22px;
              }

              &:nth-child(10) {
                animation-delay: -0.9s;
                top: 66px;
                left: 37px;
              }

              &:nth-child(11) {
                animation-delay: -1s;
                top: 62px;
                left: 52px;
              }

              &:nth-child(12) {
                animation-delay: -1.1s;
                top: 52px;
                left: 62px;
              }
            }
          }

          @keyframes lds-default {
            0%,
            20%,
            80%,
            100% {
              transform: scale(1);
            }

            50% {
              transform: scale(1.5);
            }
          }
        }
      }
    }
  }
  .chart-card {
    .chart-head {
      border-bottom: 2px solid $border-color;
      .input-dropdown {
        cursor: pointer;
        border: 2px solid $border-color;
        border-radius: 4px;
        .btn-dropdown {
          background: none;
          border: none;
          border-color: $border-color !important;
          font-size: 18px;
          line-height: 1;
          text-align: center;
          width: 30px;
          height: 30px;
          color: $secondary-color;
        }
        .divider {
          height: 22px;
          border-left: 1px solid $border-color !important;
          margin-top: auto;
          margin-bottom: auto;
        }
        img {
          height: 30px;
        }
        .icon {
          font-size: 18px;
          color: $secondary-color;
        }
        .drop {
          font-size: 18px;
          margin-top: auto;
          margin-bottom: auto;
          color: $secondary-color;
          background: none;
          flex-grow: 1;
          border: none;
          padding-right: 2rem;
          &:focus {
            border: none;
            outline: none;
          }
        }
      }
    }
  }

  @media only screen and (max-width: #{1450px}) {
    .opt-tab {
      .tab-itm {
        font-size: 0.8rem;
        padding-top: 6px !important;
        padding-bottom: 6px !important;
      }
    }
    .chart-card {
      .chart-head {
        .input-dropdown {
          .btn-dropdown {
            font-size: 10px;
            width: 20px;
            height: 20px;
          }
          img {
            height: 20px;
          }
          .icon {
            font-size: 10px;
            color: $secondary-color;
          }
          .drop {
            font-size: 10px;
          }
        }
      }
    }
    .order-book-card {
      .col-3 {
        padding-left: 4px;
        padding-right: 4px;
      }
      .opt-tab {
        .tab-itm {
          font-size: 0.8rem;
          padding-top: 6px;
          padding-bottom: 6px;
        }
      }
      .order-book-list {
        font-size: 0.5rem;
        h4 {
          font-size: 0.75rem;
        }
      }
    }
    .live-trade-card {
      .tabview-live-trades {
        .trade-list-head {
          font-size: 9px;
        }
        .trade-list {
          .live-trades-item {
            h2 {
              font-size: 20px;
            }
            .fund-list-item {
              font-size: 9px;
              h6 {
                font-size: 9px;
              }
              p {
                font-size: 7px;
              }
            }
          }
        }
      }
    }
    .card-pulse-market {
      .market-tab-view {
        .market-itm {
          .news-icon {
            height: 55px !important;
            width: 72px !important;
          }
          h3 {
            font-size: 11px;
          }
          p {
            font-size: 9px;
            line-height: 10px;
          }
          h6 {
            font-size: 8px;
            line-height: 9px;
          }
        }
        h5 {
          font-size: 12px;
        }
      }
    }
    .card-funds {
      .myfunds-tab-view {
        .tab-btn {
          padding: 4px 0;
          .tab-btn-itm {
            padding: 2px 4px;
            font-size: 11px;
          }
        }
        .fund-list {
          .fund-list-item {
            height: 40px;
            img {
              width: 22px;
            }
            h5 {
              font-size: 12px;
            }
          }
        }
      }
    }
    .card-terminal-trades {
      .trades-tab-view {
        .tab-btn {
          padding: 4px 0;
          .tab-btn-itm {
            padding: 2px 4px;
            font-size: 11px;
          }
        }
        .trade-list {
          height: 300px;
          .fund-list-head {
            font-size: 9px;
          }
          .fund-list-item {
            font-size: 9px;
            h6 {
              font-size: 9px;
            }
            p {
              font-size: 7px;
            }
          }
        }
      }
      .tabview-call-put {
        h6 {
          padding-top: 3px;
          font-size: 12px;
        }
        .input-num {
          .btn-num {
            font-size: 16px;
            width: 20px;
            height: 20px;
          }
          input {
            font-size: 11px;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
          }
          .profit {
            font-size: 11px;
            height: 20px;
          }
        }
        .input-dropdown {
          .btn-dropdown {
            font-size: 10px;
            width: 20px;
            height: 20px;
          }
          .drop {
            font-size: 11px;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            text-align: center;
          }
        }
        .btn {
          h3 {
            font-size: 18px;
          }
          @include md {
            padding: 0 !important;
            h3 {
              font-size: 14px;
              margin: 0 auto;
            }
          }
        }
      }
    }
  }
}
.mobile-content {
  .home-page {
    .card-dark {
      min-height: 450px;
    }
    .content-home-page {
      flex-direction: column;
      height: unset;
      .one {
        flex: unset;
        zoom: 0.5;
        padding: 40px;
        .contentCard {
          .content {
            height: 500px;
          }
        }
      }
      .two {
        flex: unset;
        padding: 0;
        max-width: unset;
        .tradeCardWrap {
          .contents {
            padding: 30px;
          }
        }
      }
    }
  }
}
