.educationContainer {
  //   border: 1px solid red;
  width: 100%;
  height: 88%;
  display: flex;

  & > .leftContainer {
    width: 45%;
    // border: 1px solid blue;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    padding: 2rem;
    gap: 1.5rem;
    overflow-y: scroll;
    background: #f5f5f5e1;

    & > div {
      // max-width: 550px;
      width: 100%;
    }

    & > :nth-child(1) {
      display: flex;
      justify-content: space-between;
      font-size: 1.8rem;
      font-weight: 700;
      color: #343c5b;
      align-items: center;

      & > img {
        width: 1.8rem;
        height: 1.8rem;
        cursor: pointer;
      }
    }

    & > .selectionBtns {
      display: flex;
      gap: 2rem;

      & > div {
        width: 40%;
        max-width: 150px;
        padding: 0.7rem 2.2rem;
        font-size: 1rem;
        font-weight: 700;
        cursor: pointer;
      }

      & > :nth-child(2) {
        opacity: 0.35;
        pointer-events: none;
        background: white;
      }

      & > .selectedBtn {
        background: #44c2f4;
        border-radius: 10px;
        color: white;
      }

      & > .notselectedBtn {
        background: #ffffff;
        border: 0.5px solid #ffffff;
        border-radius: 10px;
      }
    }

    & > :nth-child(3) {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      margin-top: 2rem;
    }
  }

  & > .rightContainer {
    width: 55%;
    // border: 1px solid green;
  }
}

.eachVideoContainer {
  // width: 100%;
  //   height: 30%;
  //   min-height: 15vw;
  //   min-height: fit-content;
  display: flex;
  // justify-content: center;
  align-items: center;
  border-radius: 15px;
  padding: 1rem;
  background-color: white;
  transition: all ease-in-out 0.4s;
  cursor: pointer;

  &:hover {
    box-shadow: 0 6px rgb(24 25 29 / 30%);
  }

  & > :nth-child(1) {
    min-width: 30%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // border: 1px solid red;

    & > img {
      min-height: 100%;
      max-height: 100%;
      max-width: 100%;
    }
  }

  & > .contentDiv {
    // width: 70%;
    // border: 1px solid green;
    height: 100%;
    line-height: 1.5;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    color: #343c5b;

    & > :nth-child(1) {
      font-size: 1.4rem;
      font-weight: 600;
    }

    & > :nth-child(2) {
      font-size: 0.8rem;
      font-weight: 500;
      // line-height: 1.7;
    }
  }
}

.exclusiveDiv {
  width: 100%;
  height: 100%;
  padding: 1rem 2rem;
  // border: 1px solid green;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  overflow-y: scroll;

  & > .generalCard {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    width: 100%;
    // border: 1px solid red;

    > iframe {
      display: block;
      width: 100%;
      border: 0;
      min-height: 18vw;
    }

    & > :nth-child(1) {
      width: 100%;
      height: 30%;
      min-height: 20vw;
      background: rgba(255, 255, 255, 0.1);
      border: 0.5px solid #e5e5e5;
      border-radius: 10px;
    }

    & > .contentDiv {
      display: flex;
      flex-direction: column;
      gap: 0.7rem;
      line-height: 2;

      & > :nth-child(1) {
        font-size: 2rem;
        font-weight: 700;
        line-height: 1.4;
        color: #343c5b;
      }

      & > :nth-child(2) {
        font-size: 1rem;
        font-weight: 500;
      }
    }
  }

  & > .ceoCard {
    // display: flex;
    display: none;
    padding: 1.5rem;
    background: rgba(237, 237, 237, 0.37);
    border-radius: 15px;
    max-width: 500px;
    gap: 1.5rem;

    & > :nth-child(1) {
      display: flex;
      justify-content: center;
      align-items: center;

      & > img {
        height: 100%;
      }
    }

    & > .detailsCard {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;

      & > :nth-child(1) {
        font-size: 1.5rem;
        font-weight: 700;
      }

      & > :nth-child(2) {
        font-size: 1.1rem;
        font-weight: 500;
      }
    }
  }
}

.categorybg {
  background: linear-gradient(90deg, #81cbd7 0%, #3d4394 100%);
  opacity: 0.9;
  display: flex;
  flex-direction: column;
  // align-items: center;
  // height: 20vh;
  padding-left: 1.85rem;
  padding-top: 30px;
  padding-bottom: 13vh;
  // position: absolute;
  // width: 100%;
}

.categorybgNew {
  background: linear-gradient(90deg, #81cbd7 0%, #3d4394 100%);
  opacity: 0.9;
  display: flex;
  flex-direction: column;
  // align-items: center;
  // height: 20vh;
  padding-left: 1.85rem;
  padding: 30px 0px;
  // padding-bottom: 13vh;
  // position: absolute;
  // width: 100%;
}
.welcomeMsg {
  font-size: 1.5rem;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 0.5rem;
}
.welcomeMsg1 {
  font-size: 2.5rem;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 0.5rem;
  padding-bottom: 20px;
}
.allVideos {
  background: #ffffff;
  border-radius: 35px;
  padding: 15px 25px;
  font-weight: 700;
  font-size: 12px;
  margin-right: 19px;
  &:hover {
    background: #ffffff;
    border-radius: 35px;
    padding: 15px 25px;
    font-weight: 700;
    font-size: 12px;
    margin-right: 19px;
    color: #343c5b;
    cursor: pointer;
  }
}
.videoCategory {
  border: 1px solid #ffffff;
  border-radius: 35px;
  padding: 15px 25px;
  font-weight: 700;
  font-size: 12px;
  margin-right: 19px;
  color: white;
  &:hover {
    background: #ffffff;
    border-radius: 35px;
    padding: 15px 25px;
    font-weight: 700;
    font-size: 12px;
    margin-right: 19px;
    color: #343c5b;
    cursor: pointer;
  }
}

.heroGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 43px;
  gap: 45px;
}
.leftSide {
  border: 0.5px solid #e7e7e7;
  border-radius: 10px;
}
.rightSide {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.heroTitle {
  font-weight: 700;
  font-size: 28px;
}
.heroSubTitle {
  padding-top: 10px;
  font-weight: 300;
  font-size: 16px;
}
.heroDesc {
  padding-top: 26px;
  font-weight: 300;
  font-size: 13px;
}

.nonHeroGrid {
  display: flex;
  flex-wrap: wrap;
  padding: 0px 43px;
  .nonHeroItem {
    // height: 50%;
    width: 30%;
    margin-right: 20px;
    margin-bottom: 20px;
    border-radius: 20px;
  }
}

.reactPlayer {
  border: 0.5px solid #e7e7e7;
  border-radius: 20px;
  overflow: hidden;
  width: auto;
  height: auto;

  .video {
    position: absolute;
    object-fit: fill;
  }
}

.reactPlayer1 {
  border: 0.5px solid #e7e7e7;
  border-radius: 20px;
  overflow: hidden;

  // max-height: 200px;
  // // max-width: 50%;
  // max-width: 22vw;
  .video {
    position: absolute;
    object-fit: fill;
  }
}
.nonHeroTitle {
  font-weight: 700;
  font-size: 16px;
  padding-top: 26px;
}

.nonHeroSubTitle {
  font-weight: 700;
  font-size: 14px;
  color: gray;
}
.imageStyle {
  width: 100%;
  border-radius: 10px;
}
