.createPlanBWhole {
  // border: 1px solid red;
  padding: 60px 2rem;
  width: 100%;
  height: 100%;
  padding-bottom: 0;
  position: relative;

  & > .closeBtn {
    position: absolute;
    top: 2rem;
    right: 2rem;
    padding: 1.5rem;
    border-radius: 50%;
    background: #44c2f4;
    color: white;
    font-weight: 600;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 1.5rem;
    transition: all ease 0.4s;

    &:hover {
      scale: 1.2;
    }
  }

  & > .loadingDiv {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background: #00000015;
    pointer-events: none;

    & > div {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      & > img {
        z-index: 2;
      }
    }
  }
}
