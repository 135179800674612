.fileModalWrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  .overlay {
    background: rgba(0, 0, 0, 0.4);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 0;
  }
  .selectFileModal {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    flex: 0 0 40%;
    max-height: 43%;
    position: absolute;
    width: 350px;
    z-index: 2;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    display: flex;
    justify-content: space-evenly;
    .head {
      font-size: 24px;
      font-weight: 600;
      text-align: center;
    }
    .preview {
      margin: 20px 0;
      width: 85%;
      height: fit-content;
      border: 1px solid $border-color;
      position: relative;
      svg {
        width: 100%;
        height: auto;
      }
      .previewItem {
        object-fit: contain;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        width: 100%;
        height: 100%;
        &.file {
          padding: 30px;
        }
      }
    }
    .textInput {
      width: 85%;
      padding: 2px 8px;
    }
    .btnSend {
      cursor: pointer;
      margin: 20px 0;
      width: 45%;
      height: 40px;
      background: $primary-color;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      font-weight: 600;
    }
  }
}
